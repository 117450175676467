<template>
  <div class="form_field">
    <label>{{ isZh ? "国家": "Country" }} <span class="required">*</span></label>
    <input class="form_control" name="countryName" type="hidden" />

    <div ref="countrySelectRoot" class="ap_country_list">
      <input :placeholder="isZh ? '选择国家' : 'Select Country'" v-model="selectCountryText" @focus="openCountryDD"/>
      <div class="countryDD" :class="{show: isActive}">
        <div class="c_name" v-for="(country, index) in searchCountries" :key="`${country.code}-${index}`" @click="
      checkUserCountryId(
        country.id,
        isZh ? country.c_name : country.e_name
      )
      ">
          <span :class="'flag-icon flag-icon-' +
      (country.iso_2
        ? ['TW', 'HK', 'MO'].includes(country.iso_2) ? 'cn' : country.iso_2.toLowerCase()
        : '')
      "></span>
          <span>
            {{ isZh ? country.c_name : country.e_name }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="!showErrorCountry" class="errorInput">
      {{ isZh ? "请选择国家" : "Please Select Country" }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import util from "@/libs/util.js";
import "flag-icon-css/css/flag-icons.css";

export default {
  name: "select-country",
  props: {
    isZh: {
      type: Boolean,
      default: false,
    },
    countryId: {  
      type: String,  
      default: ''  
    },
  },
  data() {
    return {
      countries: [],
      isActive: false,
      selectCountryText: "",
      showErrorCountry: true,
    }
  },
  computed: {
    searchCountries() {
      if(this.selectCountryText === "" || this.selectCountryText === null || this.selectCountryText === undefined) {
        return this.countries;
      }else {
        return this.countries.filter(el => {
          return el.c_name.includes(this.selectCountryText) || el.e_name.toLowerCase().includes(this.selectCountryText.toLowerCase())
        })
      }
    }
  },
  methods: {
    handleBlur() {
      this.isActive = false;
      if(this.countryId === "" || this.countryId === null || this.countryId === undefined) {
        this.selectCountryText = "";
      }else {
        const i = this.countries.find(el => el.id === this.countryId);
        if(i) {
          this.selectCountryText = this.isZh ? i.c_name : i.e_name;
        }else {
          this.selectCountryText = "";
        }
      }
    },
    handleDocumentClick(event) {
      // 检查点击是否发生在 myDiv 元素或其子元素之外  
      if (this.$refs.countrySelectRoot && !this.$refs.countrySelectRoot.contains(event.target)) {  
        // 在这里执行你想要的逻辑
        console.log('Clicked outside the div');
        this.handleBlur();
        event.stopPropagation(); // 阻止事件冒泡（如果需要）  
      } else {
        console.log('Clicked inside the div');  
      }
    },
    openCountryDD() {
      this.isActive = true;
    },
    checkUserCountryId(id, name) {
      this.isActive = false;
      this.$emit('update:countryId', id);
      this.selectCountryText = name;
      if (!id) {
        this.showErrorCountry = false;
      } else {
        this.showErrorCountry = true;
      }
    },
    showErrorCountryM() {
      this.showErrorCountry = false;
    },
  },
  mounted() {
    axios.post(`${util.baseAdmin}/user/basecode/getAllCountryList`, {
      headers: {
        "Content-Language": this.isZh ? "CN" : "EN",
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
        if(response.data.code === "success") {
          this.countries = response.data.row;
        }
    })
    .catch(error => {
      console.error(error);
    });
    document.addEventListener('click', this.handleDocumentClick);
  },
  unmounted() {
    document.removeEventListener('click', this.handleDocumentClick);
  },
}
</script>

<style>
.ap_country_list {
  position: relative;
}

.ap_country_list > input {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background-color: var(--white-color);
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
  display: block;
  overflow-y: scroll;
}

.ap_country_list {
  position: relative;
}

.ap_country_list > span {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background: #fff;
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
  visibility: hidden;
}

.ap_country_list .countryDD.show {
  visibility: visible;
}

.ap_country_list .countryDD .c_name {
  background: #fff;
  padding: 3px 15px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.ap_country_list .countryDD .c_name:hover {
  background: #e9e9e9;
}

.ap_country_list .countryDD .c_name .flag-icon {
  margin-right: 15px;
}
.ap_country_list::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: transparent;
  z-index: 9;
  transform: rotate(45deg) translateY(-50%);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  right: 20px;
  top: 40%;
  pointer-events: none;
}
</style>