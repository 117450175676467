<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">客户数据安全第一</h1>
                  <p class="cntnt">
                    防止欺诈活动，确保企业业务和客户数据的安全。
                  </p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/Fraud_prevention_banner.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">我们的优势</h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>辨识空号</strong>
                      <span>高效辨识号码是否是空号 </span>
                    </li>
                    <li>
                      <strong>识别归属地</strong>
                      <span>高效识别号码归属国家/地区 </span>
                    </li>
                    <li>
                      <strong>识别运营商</strong>
                      <span>高效识别号码归属运营商 </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">多产品防欺诈保护</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-3"
                  data-bs-toggle="tab"
                  data-bs-target="#FraudProtector"
                  type="button"
                  role="tab"
                  aria-controls="FraudProtector"
                  aria-selected="true"
                >
                  <div class="icon_text">
                    <strong> 防欺诈管理 </strong>
                  </div>
                  <span> 帮助客户实现防欺诈、识别和阻断刷量风险，降低运营成本。 </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#numberCheck"
                  type="button"
                  role="tab"
                  aria-controls="numberCheck"
                  aria-selected="false"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/Number_check.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> 号码检测</strong>
                  </div>
                  <span> 对客户号码进行批量筛选和检测，智能识别号码风险。 </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#OTA"
                  type="button"
                  role="tab"
                  aria-controls="OTA"
                  aria-selected="true"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/OTA.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> 一键登录（OTA） </strong>
                  </div>
                  <span>
                    让用户摆脱繁琐的登录流程，轻松无忧畅行在每个应用中。
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="numberCheck"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Authentic_promotions.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">营销推广</h4>
                    <p class="cntnt">
                      批量检测号码，识别空号、错号等，<br />再进行精准的营销推送，降本增效。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Approve_better.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">信息审核</h4>
                    <p class="cntnt">
                      核验用户号码来确认用户身份的真实性和准确性，减少虚假账户和盗刷风险。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="OTA"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Seamless_customer_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">注册/登录</h4>
                    <p class="cntnt">
                      通过简化登录流程，为用户提供畅通无阻的通道和瞬时登录的卓越体验。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Advanced_security_measures.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">防范刷单</h4>
                    <p class="cntnt">
                      基于四维防护体系，有效识别羊毛党、黄牛等风险设备，确保业务的安全性。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Frictionless_experience.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">支付确认</h4>
                    <p class="cntnt">
                      用于线上支付、转账等敏感操作上，提升账户安全性，避免盗用转账。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="FraudProtector"
                role="tabpanel"
                aria-labelledby="tab-3"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/Protection.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      自定义防护
                    </h4>
                    <p class="cntnt">
                      支持流量控制、号段控制、国家方向控制等丰富的自定义保护方式。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/Real_Time.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      实时监控 
                    </h4>
                    <p class="cntnt">
                      通过 7*24 持续监控，主动预防欺诈、及时截停风险。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/Binocular.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      可视化告警
                    </h4>
                    <p class="cntnt">
                      检测不常见的 SMS 模式，发出告警信息或暂停流量以管理风险。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ZhProductFraudPrevention"
};
</script>
