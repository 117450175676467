<template>
  <main>
    <section class="terms">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="terms-hdng">NXCLOUD COOKIE POLICY</h1>
            <p class="terms-desc mb-0">Update date: February 19th, 2024</p>
            <p class="terms-desc term-days">
              Effective date:February 19th, 2024
            </p>

            <p class="terms-desc">
              This Cookie Policy explains how NXCLOUD ("NXCLOUD", "we", "our" and "us") uses Cookie in connection with our Website and all products and services that contain or refer to this Cookie Policy (collectively "Services").
            </p>
            <p class="terms-desc">
              Please see our Privacy Statement for details on how we process your personal data.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 1: WHAT ARE COOKIES</h2>
            <p class="terms-desc">
              Cookies are small data files that are placed on your computer or mobile device when you visit a website. The content of a Cookie can be retrieved and read only by the server that created the Cookie. Cookies are unique to the browser or mobile application you are using. Cookies serve different purposes, like helping us understand how a site is being used, letting you navigate between pages efficiently, remembering your preferences and generally improving your browsing experience.
            </p>
            <p class="terms-desc">
              We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer or mobile device for six months, or until you withdraw your consent or delete them, as applicable).
            </p>
            <p class="terms-desc">
              We use four broad categories of Cookies:
              <ul class="article article-Registration">
              <li>
                a.	Strictly necessary Cookie: For login and verification. When you use your user account to log in to a website, the session Cookie ensures that your visit to this site functions as smoothly as possible.
              </li>
              <li>
                b.	Personalization Cookie: For storing your preferences and settings. We use Cookies to save settings, such as the language setting and font size on your computer or mobile device, items in your shopping cart, and other browser preferences.
              </li>
              <li>
                c.	Statistical Cookie. We collect information about your use of the Services, either for a single visit (using a session cookie) or for repeated visits (using a persistent cookie). These Cookies help us understand how the Services are performing and being used.
              </li>
              <li>
                d.	Advertising Cookie. These Cookies are used by advertising companies to collect information about how you use the Services over time.
              </li>
              </ul>
            </p>
            <p class="terms-desc">
              <strong> Many of the Services require Cookies to operate. If you choose not to allow Cookies related to these Services, you may not be able to use those Services or certain features of the Services.</strong>
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 2: DO NOT TRACT</h2>
            <p class="terms-desc">
              Many web browsers provide a Do Not Track function that can release Do Not Track requests to websites. Currently, major Internet standards organizations have not established policies to specify how websites should handle these requests.
            </p>
            <p class="terms-desc">
              If you enable Do Not Track or other similar functions your browser may provide, NXCLOUD will not change the 
              way we collect and use your data as described in this Cookie Policy and our Privacy Statement. However, we reserve the right to respond to your Do Not Track request and stop the collection of your data without prior notice to you.
            </p>
            <h2 class="terms-list-hdng privacy-hdng">SECTION 3: YOUR CHOICES</h2>
            <p class="terms-desc">
              Most browsers let you remove or reject Cookies. To do this, follow the instructions in your browser settings. Many browsers accept Cookies by default until you change your settings.
              If you do not accept our Cookies, you may experience some inconvenience in your use of the Website. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit the Website.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
  
<script>
export default {
  name: "ZhCookiePolicy",
};
</script>
<style scoped>
.article-Registration {
  padding-left: 24px;
}
</style>