<template>

    <main>
      <!-- rates -->
      <section class="rates pb_200">
        <div class="container">
          <div class="row row-rates">
            <div class="col-lg-6 col-md-12 text-center">
              <img
                src="../../assets/images/pricing/price-figure.svg"
                class="img-fluid pricing_hero"
                alt="price-figure"
              />
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="row row-rates-card-wrap">
                <div class="col-lg-6 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <!-- <h4 class="rates-hdng">Message</h4>
                      <div class="rates-figure">
                        <img src="assets/images/pricing/msg.png" alt="msg" />
                      </div> -->

                      <div class="Countries-wrap">
                        <div class="icon_item">
                          <i class="icon">
                            <img
                              src="../../assets/images/pricing/msg.png"
                              alt="icon"
                            />
                          </i>
                          <h4 class="Countries-hdng">Message</h4>
                        </div>
                        <ul>
                          <li class="text-dark text-center fs_16">
                            <strong>Countries & Rates (USD/msg)</strong>
                          </li>
                          <li>
                            <div class="rates-list">
                              <p>Indonesia</p>
                              <p>0.2846</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Russia</p>
                              <p>0.2298</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Pakistan</p>
                              <p>0.3320</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Vietnam</p>
                              <p>0.1021</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Saudi Arabia</p>
                              <p>0.1600</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Egypt</p>
                              <p>0.1926</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Nigeria</p>
                              <p>0.1978</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Philippines</p>
                              <p>0.1481</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Iraq</p>
                              <p>0.1759</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>India</p>
                              <p>0.0537</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <!-- <h4 class="rates-hdng">Voice</h4>
                      <div class="rates-figure">
                        <img src="assets/images/pricing/call.png" alt="call" />
                      </div> -->

                      <div class="Countries-wrap">
                        <div class="icon_item">
                          <i class="icon">
                            <img
                              src="../../assets/images/pricing/call.png"
                              alt="icon"
                            />
                          </i>
                          <h4 class="Countries-hdng">Voice</h4>
                        </div>
                        <ul>
                          <li class="text-dark text-center fs_16">
                            <strong>Countries & Rates (USD/min)</strong>
                          </li>
                          <li>
                            <div class="rates-list">
                              <p>Indonesia</p>
                              <p>0.0676</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>India</p>
                              <p>0.0305</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Philippines</p>
                              <p>0.1435</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Vietnam</p>
                              <p>0.1080</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Colombia</p>
                              <p>0.0207</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Mexico</p>
                              <p>0.0082</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Thailand</p>
                              <p>0.0806</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>United States</p>
                              <p>0.0119</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Chile</p>
                              <p>0.0360-1.0500</p>
                            </div>
                          </li>

                          <li>
                            <div class="rates-list">
                              <p>Peru</p>
                              <p>0.0150-0.4000</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <p class="more_info text-center">
                    Also available in more countries
                  </p>
                </div>
                <div class="col-md-12">
                  <div class="rates-btn">
                    <router-link to="/sign-up" class="btn btn-success"> 
                      Sign Up Now
                      <img
                        src="../../assets/images/sign-up.svg"
                        class="arrow-right"
                        alt="arrow-right"
                      />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cts">
        <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
        <div class="container">
          <div class="row row-cts">
            <div class="col-md-8">
              <h3 class="archived-hdng cts-hdng">
                Start offering your customers a better service experience
              </h3>
            </div>

            <div class="col-md-4">
              <div class="cts-btn">
                <router-link to="/sign-up" class="btn btn-success"> 
                    Get Started
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

  </template>    

    <script>
        export default {
            name: 'PricingPage'
        }
    </script>


