<template>
  <footer class="main-footer">
    <div class="cookie_wrap" v-show="cookieShow">
      <div class="text">
        点击 "允许所有"，即表示您同意在您的设备上存储Cookie，以加强网站导航、分析网站使用情况以及协助我们的营销工作。您可以阅读有关<router-link to="/zh-cookie-policy">Cookie 政策</router-link>的更多信息。
      </div>
      <div class="right">
        <span class="cookie_btn" @click="hiddleTips">允许所有</span>
        <span class="cookie_btn cookie_btn_cancel" @click="hiddleTips">禁止</span>
      </div>
    </div>
    <div class="chatbot_wrap" :class="{ active: isChatbot }">
      <button class="chatbot_btn" @click="toggleChatBox">
        <img src="../assets/images/Chat.svg" alt="chatbot" />
      </button>
      <ul class="list">
        <li>
          <a href="javascript:void(0)" @click="handleToCs">
            <i class="icon">
              <img
                src="../assets/images/bot_live_chat.svg"
                alt="bot_live_chat"
              />
            </i>
            <span class="text">Live Chat</span>
          </a>
        </li>
        <li>
          <a href="javascript:void(0)">
            <i class="icon">
              <img src="../assets/images/bot_wechat.svg" alt="bot_wechat" />
            </i>
            <span class="text">WeChat</span>
          </a>
          <div class="child"><h2>微信扫一扫<br>即刻开始对话</h2><img src="../assets/images/wechat_acc.png"></div>
        </li>
        <li>
          <a href="javascript:void(0)">
            <i class="icon">
              <img src="../assets/images/bot_phone.svg" alt="bot_phone" />
            </i>
            <span class="text">Phone</span>
          </a>
          <div class="child"><h2>客服电话咨询<br>400-7800-900</h2></div>
        </li>
        <li>
          <a href="javascript:void(0)">
            <i class="icon">
              <img
                src="../assets/images/official_account.svg"
                alt="bot_live_chat"
              />
            </i>
            <span class="text">Chat</span>
          </a>
          <div class="child"><h2>微信扫一扫<br>关注我们</h2><img src="../assets/images/agent_acc.png"></div>
        </li>
      </ul>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <router-link to="/zh">
            <img
              src="../assets/images/footer_logo.svg"
              alt="NXCLOUD"
              class="img-fluid"
            />
          </router-link>
          <div class="footer-icons-wrap">
            <div class="footer-icons">
              <a
                href="https://www.facebook.com/people/NXCLOUD/100063278852731/"
                target="_blank"
              >
                <img src="../assets/images/fb.svg" class="img-fluid" alt="fb"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.linkedin.com/company/nxcloud/"
                target="_blank"
                ><img
                  src="../assets/images/li.svg"
                  class="img-fluid"
                  alt="linkdin"
              /></a>
            </div>
            <div class="footer-icons">
              <a href="https://twitter.com/NXCLOUD1" target="_blank">
                <img src="../assets/images/x.svg" class="img-fluid" alt="tw"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://www.instagram.com/nxcloud_communication/"
                target="_blank"
                ><img
                  src="../assets/images/instagram-white-icon.svg"
                  class="img-fluid"
                  alt="en"
              /></a>
            </div>
            <div class="footer-icons">
              <a
                href="https://weibo.com/u/7049459810"
                target="_blank"
                ><img
                  src="../assets/images/weibo.svg"
                  class="img-fluid"
                  alt="en"
              /></a>
            </div>
          </div>
        </div>

        <div class="col-md-8">
          <div class="row">
            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">沟通</h5>
                <ul>
                  <li>
                    <router-link to="/zh-product-message"> 消息 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-voice"> 语音 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-conversation">
                      互动对话
                    </router-link>
                  </li>
                </ul>

                <h5 class="footer-list-hdng solution-hdng">应用</h5>
                <ul>
                  <li>
                    <router-link to="/zh-product-fraud-prevention">
                      防欺诈策略
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-global-number">
                      全球号码
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-product-customer-engagement">
                      客户互动
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">行业</h5>
                <ul>
                  <li>
                    <router-link to="/zh-industry-e-commerce">
                      跨境电商
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-education">
                      在线教育
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-fintech">
                      高新技术
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-gaming">
                      出海游戏
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-logistics">
                      跨境物流
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-online-social">
                      在线社交
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-industry-retail">
                      实体零售
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-4">
              <div class="footer-list-wrap">
                <h5 class="footer-list-hdng">公司</h5>
                <ul>
                  <li>
                    <router-link to="/zh-company"> 关于我们 </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-terms-services">
                      服务协议
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/zh-privacy-policy">
                      隐私政策
                    </router-link>
                  </li>
                  <li class="small-hide">
                    <a href="/news">新闻动态</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Icp />
  </footer>
</template>

<script>
import Icp from "./components/icp.vue";
export default {
  name: "FooterCh",
  components: {
    Icp,
  },
  data() {
    return {
      changeTabFromUrl: "",
      isChatbot: false,
      isChatFormContainer: false,
      isChatFormWrap: false,
      isThankYou: false,
      cookieShow: true,
    };
  },
  mounted() {
    // Pehli baar ID ko retrieve karna
    this.getIdfromUrl();
    // Vue Router ka afterEach hook register karna
    this.$router.afterEach(() => {
      // Har inner page change hone par ID ko retrieve karna
      this.getIdfromUrl();
    });
    if (localStorage.getItem("nx_cookies")) {
      this.cookieShow = false;
    }
  },

  methods: {
    // changeTab function
    changeTab() {
      console.log("changeTabFromUrl " + this.changeTabFromUrl);
      const tabBtn = `[data-bs-target="#${this.changeTabFromUrl}"]`;
      console.log(tabBtn);
      setTimeout(() => {
        const targetElement = document.querySelector(tabBtn);
        if (targetElement) {
          targetElement.click();
          document.querySelector(".prod_desc_section").scrollIntoView({
            behavior: "instant",
          });
        } else {
          console.error(`Element with selector ${tabBtn} not found.`);
        }
      }, 0);
    },

    // getIdfromUrl for acti  ve tab on change
    getIdfromUrl() {
      // Pura URL le lena ke bajayf sirf query string le lena.
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      // ID parameter ko extract karna.
      const id = urlParams.get("id");
      if (id) {
        this.changeTabFromUrl = id;
        this.changeTab();
      }
    },
    // Toggle Chatbot
    toggleChatBox(event) {
      event.preventDefault();
      if (!this.isChatbot) {
        this.isChatbot = true;
      } else {
        this.isChatbot = false;
      }
    },
    // Toggle Chatbot Form
    toggleChatForm(event) {
      event.preventDefault();
      if (!this.isChatFormContainer) {
        this.isChatFormContainer = true;
        this.isChatFormWrap = true;
      } else {
        this.isChatFormContainer = false;
        this.isChatFormWrap = false;
      }
    },
    // Close chatbot form
    closeChatForm(event) {
      event.preventDefault();
      if (this.isChatFormContainer) {
        this.isChatFormContainer = false;
        this.isThankYou = false;
      }
    },
    // Chatbot form submit
    submitChatForm(event) {
      event.preventDefault();
      if (!this.isThankYou) {
        this.isThankYou = true;
        this.isChatFormWrap = false;
      }
    },
    // Thankyou done
    thankyouDone(event) {
      event.preventDefault();
      this.isChatbot = false;
      this.isChatFormContainer = false;
      this.isChatFormWrap = false;
      this.isThankYou = false;
    },
    hiddleTips() {
      localStorage.setItem("nx_cookies", '1');
      this.cookieShow = false;
    },
    handleToCs(event) {
      event.preventDefault();
      window.open(
        "https://p.qiao.baidu.com/cps/chat?siteId=17678764&userId=38723953&siteToken=f8f3199714771c412f9dd352ddb23ee5",
        "newwindow",
        "height=750, width=900, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      );
    },
  },
};
</script>

<style scoped>
.contact_sec .form_wrap {
  display: none;
}
.contact_sec .form_wrap.active {
  display: block !important;
}
.chatbot_wrap .list li:hover .child {
  display: inline-block;
}
.chatbot_wrap .list li .child h2 {
    font-size: 14px;
    line-height: 20px;
    color: #6b7890;
}
.chatbot_wrap .list li .child {
    min-width: 140px;
    max-width: 140px;
    padding: 7px;
    text-align: center;
    display: none;
    position: absolute;
    right: 70px;
    top: 0;
    background: #fff;
    box-shadow: 0px 4px 25px 0px rgba(0,0,0,0.15);
    border-radius: 8px;
}

.chatbot_wrap
  .list
  li.chatbotFormPopup
  .chatbotFormPopupBox
  .thankyou_wrap.active {
  display: block !important;
}
</style>
