<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">Omnichannel at your fingertips</h1>
                  <p class="cntnt">
                    Enhances business communication across SMS, Viber, WhatsApp,
                    and more.
                  </p>
                  <router-link to="/sign-up" class="cmn_btn light">
                    Start now
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/product_conversation_banner.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">
                    All in one just for you
                  </h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>Industry Experts</strong>
                      <span
                        >Extensive industry expertise, serving over 10,000
                        companies and aiding in global expansion.
                      </span>
                    </li>
                    <li>
                      <strong>Expansive Channel Coverage</strong>
                      <span
                        >Operates across 185+ countries and regions, backing
                        SMS, Viber, WhatsApp, and various favored channels.
                        Multilingual support available.
                      </span>
                    </li>
                    <li>
                      <strong>Official Certification</strong>
                      <span
                        >Building on official partnerships, NXCLOUD ensures
                        stable and uninterrupted message transmission through
                        established information channels.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">Seamless connection</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#superMessaging"
                  type="button"
                  role="tab"
                  aria-controls="superMessaging"
                  aria-selected="true"
                >
                  <strong> Super Messaging API </strong>
                  <span>
                    Merge chat platforms into a centralized hub for efficient
                    business management with our proprietary technology.
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#whatsappBusiness"
                  type="button"
                  role="tab"
                  aria-controls="whatsappBusiness"
                  aria-selected="false"
                >
                  <strong> WhatsApp Business</strong>
                  <span>
                    Engage your customers better with WhatsApp for Business
                    solutions
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-3"
                  data-bs-toggle="tab"
                  data-bs-target="#viberBusiness"
                  type="button"
                  role="tab"
                  aria-controls="viberBusiness"
                  aria-selected="false"
                >
                  <strong> Viber Business </strong>
                  <span>
                    Power your business with Viber for Business solutions
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-4"
                  data-bs-toggle="tab"
                  data-bs-target="#zaloBusiness"
                  type="button"
                  role="tab"
                  aria-controls="zaloBusiness"
                  aria-selected="false"
                >
                  <strong> Zalo Business</strong>
                  <span>
                    Reach your customers via SMS with Zalo Business solutions
                  </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="superMessaging"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Omnichannel_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Omnichannel <br />
                      Marketing
                    </h4>
                    <p class="cntnt">
                      Enhance brand visibility and sales across new product
                      launches, e-commerce, financial customer acquisition and
                      discounts using Super Messaging API's omnichannel
                      promotion capabilities.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Customer_first.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Customer First</h4>
                    <p class="cntnt">
                      Tailored for logistics updates, order inquiries, and
                      customer support, our platform facilitates real-time
                      interaction, prompt information delivery, and swift issue
                      resolution, elevating the overall customer experience.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Business_growth.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Business Growth</h4>
                    <p class="cntnt">
                      Boost efficiency in business conversations including sales
                      negotiations, contract finalization, and solution
                      discussions, enabling precise price negotiations and
                      seamless document exchange for improved efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="whatsappBusiness"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Connected_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Connected <br />
                      Service
                    </h4>
                    <p class="cntnt">
                      Integrate your customer service with WhatsApp for seamless
                      consumer-company communication, swiftly resolving
                      complaints and inquiries.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/End-to-End.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">End-to-End</h4>
                    <p class="cntnt">
                      Deliver post-purchase information, logistics updates, and
                      order details, improving the customer experience and
                      reducing churn.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Targeted_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Targeted Marketing</h4>
                    <p class="cntnt">
                      Send exclusive coupons, return purchase incentives,
                      personalized product recommendations and promotions to
                      former customers to encourage repeat purchases.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="viberBusiness"
                role="tabpanel"
                aria-labelledby="tab-3"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Customer_engagement.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Customer <br />engagement</h4>
                    <p class="cntnt">
                      Integrate your customer service with Viber for seamless
                      consumer-company communication, swiftly resolving
                      complaints and inquiries.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/After_sales_notifications.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">After-sales notifications</h4>
                    <p class="cntnt">
                      Push after-sales notifications about transactional
                      information, shipping updates, and membership rewards to
                      provide the ultimate service experience and improve
                      customer retention.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Reengage_inactive_customers.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Re-engage with inactive customers</h4>
                    <p class="cntnt">
                      Reach inactive customers about your latest coupons,
                      discounts, and new products to reengage with them and
                      achieve your secondary conversion goals
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="zaloBusiness"
                role="tabpanel"
                aria-labelledby="tab-4"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Account_verification.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Account <br />verification</h4>
                    <p class="cntnt">
                      Send verification codes directly to users' Zalo accounts,
                      empowering seamless and reliable identity verification.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Order_notifications.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">
                      Order <br />
                      notifications
                    </h4>
                    <p class="cntnt">
                      Notify customers of order status updates, transaction
                      information, billing, and other information to provide a
                      high-quality service experience.
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Post_purchase_feedback.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">Post-purchase feedback</h4>
                    <p class="cntnt">
                      Easily invite customers to evaluate your products and
                      services to monitor customer satisfaction rates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ProductConversation",
};
</script>
