<template>
  <HeaderFooterGlobal />
</template>

<script>
import HeaderFooterGlobal from './components/HeaderFooterGlobal.vue'

// Aos
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  name: 'App',
  components: {
    HeaderFooterGlobal,
  },
  methods: {
    async checkFromChina() {
      try {
        // 获取访问者的 IP 地址（这里使用 ipinfo.io 提供的服务）
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();
      
        // 提取国家信息
        const country = data.country;
      
        // 判断是否在中国境内
        if (country === 'CN') {
          this.$store.commit('updateFromChina', true);
        } else {
          this.$store.commit('updateFromChina', false);
        }
      } catch (error) {
        console.error('无法获取 IP 地址信息。', error);
        this.$store.commit('updateFromChina', false);
      }
    },
  },
  mounted() {
    AOS.init(
      {
        // Global settings:
        disable: false,
        startEvent: 'DOMContentLoaded',
        initClassName: 'aos-init',
        animatedClassName: 'aos-animate',
        useClassNames: false,
        disableMutationObserver: false,
        debounceDelay: 50,
        throttleDelay: 99,
        offset: 120,
        delay: 0,
        duration: 2000,
        easing: 'ease-in-out-back',
        once: true,
        mirror: false, 
        anchorPlacement: 'top-bottom'
      }
    );
    this.checkFromChina();
  }
}

</script>
