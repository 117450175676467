<template>
  <div v-if="!isMobile" class="center-box">
    <h2 class="service-h2">新闻动态</h2>

    <div v-if="news.length > 0" class="nxcloud-index-news">
      <div class="index-news-box wow fadeInLeft animated">
        <div class="index-news-box-title">
          {{ news[newsIndex].title }}
        </div>
        <div class="index-news-box-description">
          <div class="index-news-box-description-shape"></div>
          <div class="text">{{ news[newsIndex].content }}</div>
        </div>

        <div class="index-news-image-box">
          <img :src="news[newsIndex].iurl" v-if="news[newsIndex].iurl" />
          <!-- <img :src="require(`../news/image/${news[newsIndex].fullImg}`)" v-else-if="news[newsIndex].fullImg" />
          <img :src="require(`../news/image/${news[newsIndex].img}`)" v-else-if="news[newsIndex].img" /> -->
        </div>

        <div class="index-news-box-footer">
          <Button class="primary_btn" style="border-color: transparent;"
            @click="() => toNewsDetail(news[newsIndex].path)">了解更多</Button>
          <div class="index-news-box-time">{{ news[newsIndex].time }}</div>
        </div>
      </div>

      <div class="index-news-list wow fadeInRight animated">
        <div class="index-news-item" v-for="(item, index) in news" :key="item.title"
          @mouseenter="() => handleNewsMouseMove(index)">
          <div :class="`index-news-item-title ${newsIndex === index ? 'active' : ''}`
            ">
            <span class="index-news-item-title-text">
              <span class="icon-ul-circle"></span>
              <div class="text">
                {{ item.title }}
              </div>
            </span>
            <span class="index-news-item-time">{{ item.time }}</span>
          </div>

          <div class="index-news-item-content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="index-list">
    <div class="new-title new-title-black">新闻动态</div>
    <div class="news_content">
      <ul>
        <li class="clearfix" v-for="(item, index) in news" :key="index" @click="() => toNewsDetail(item.path)">
          <img v-if="item.iurl" :src="item.iurl" />
          <!-- <img
              :src="require(`../web-lastest/news/image/${item.fullImg}`)"
              v-else-if="item.fullImg"
            />
            <img
              :src="require(`../web-lastest/news/image/${item.img}`)"
              v-else-if="item.img"
            /> -->
          <div class="news_content_txt">
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
            <span>{{ item.time }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-zh-news",
  data() {
    return {
      news: [],
      newsIndex: 0,
      isMobile: false,
    }
  },
  methods: {
    async getNewsList() {
      const fetchData = await fetch(
        "https://wp.nxcloud.com/wp-json/news/v1/list"
      );
      const data = await fetchData.json();
      this.news = data.map(item => {
        const date = item.date.split(":");
        date.pop();
        const iurl = item.img.match(/src="(.*?)"/);
        return {
          iurl: Array.isArray(iurl) && iurl[1],
          type: "公司动态",
          title: item.title,
          content: item.excerpt,
          time: date.join(":"),
          readCount: 100,
          path: `/news/${item.ID}.html`,
        };
      });
      sessionStorage.setItem("news_list", JSON.stringify(this.news));
    },
    handleNewsMouseMove(index) {
      this.newsIndex = index;
    },
    toNewsDetail(path) {
      if (path) window.location.href = path;
    },
    handleResize() {  
      this.isMobile = window.innerWidth <= 991.98;
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);  
    this.getNewsList();
  },
  beforeUnmount() {  
    window.removeEventListener('resize', this.handleResize);  
  },  
}
</script>

<style lang="less" scoped>
.center-box {
  width: 1200px;
  margin: 0 auto;
}

.service-h2 {
  text-align: center;
  font-size: 36px;
  line-height: 36px;
  color: #04123d;
  margin-bottom: 16px;
  margin-top: 80px;
}

.nxcloud-index-news {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 80px;
}

.index-news-box {
  width: 480px;
  height: 498px;
  background: rgba(121, 176, 0, 0.03);
  border-radius: 20px;
  padding: 24px 32px;
}

.index-news-box-title {
  font-size: 22px;
  font-weight: 500;
  color: #333333;
  line-height: 26px;
}

.index-news-box-description {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  display: flex;
  border-left: #fdba0d 4px solid;
  padding-left: 12px;
  margin-top: 22px;
  margin-bottom: 16px;
}

.index-news-box-description .text {
  font-size: 16px;
  height: 44px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical
}

.index-news-image-box {
  width: 416px;
  height: 234px;
  background: #c4c4c4;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-news-image-box img {
  width: 100%;
  transition: all .4s linear
}

.index-news-image-box:hover img {
  transform: scale(1.4);
  transition: all .4s linear
}

.index-news-box-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.index-news-box-time {
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
}

.index-news-list {
  border-bottom: 1px solid #e6e6e6;
}

.index-news-item {
  width: 640px;
  padding: 20px 0;
  border-top: 1px solid #e6e6e6;
  cursor: pointer;
}

.index-news-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
}

.index-news-item-title.active {
  color: #85c100
}

.index-news-item-title-text {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.index-news-item-title-text .text {
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.index-news-item-content {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-top: 1em;
  padding-left: 16px;
  text-align: justify;

  display: -webkit-box;
  overflow: hidden;
  height: 72px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>

<style lang="less" scoped>
.index-list {
  margin: 25px 15px 50px 15px;

  &-item {
    border-bottom: 1px solid #E2E5EF;
    padding-bottom: 31px;
    margin-bottom: 48px;
  }

  .big-img {
    width: 100%;
    margin-bottom: 48px;

  }

  .index-normal-head {
    display: flex;
    font-size: 18px;
    color: #04123D;
    margin-bottom: 24px;
    line-height: 24px;

    img {
      height: 24px;
      margin-right: 8px;
    }
  }

  .index-normal-h2 {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    color: #04123D;
  }

  .index-normal-p {
    margin: 16px 0 24px;
    font-size: 16px;
    line-height: 26px;
    color: #6B7890;
  }

  .more-btn {
    background: #0265F2;
    border-radius: 4px;
    width: 140px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin: 0 auto;
  }
}

.new-title {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

.new-title-black {
  color: #04123D;
}

.new-title-white {
  color: #ffffff;
}

.news_content {
  width: 100%;

  ul {
    width: 100%;

    li {
      width: 100%;
      padding-top: 17px;
      padding-bottom: 17px;
      border-bottom: 1px solid #F1F1F1;

      img {
        width: 88px;
        height: 88px;
        float: left;
        margin-right: 10px;
        object-fit: cover;
      }

      .news_content_txt {
        width: calc(100% - 98px);
        float: left;

        h3 {
          width: 100%;
          text-align: left;
          font-size: 15px;
          line-height: 20px;
          color: #333333;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        p {
          width: 100%;
          height: 40px;
          text-align: left;
          font-size: 12px;
          color: #666666;
          line-height: 20px;
          margin-bottom: 5px;
          overflow: hidden;
          display: -webkit-box;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          -webkit-line-clamp: 2;
        }

        span {
          width: 100%;
          text-align: left;
          font-size: 12px;
          color: #999999;
          line-height: 20px;
        }
      }
    }

    li:first-child {
      padding-top: 0;
    }

    li:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}
</style>