<template>
  <main>
    <section class="common-section">
      <div style="background-color: white; padding: 120px 0;">
        <div class="container common-column-center">
          <div class="common-row-center title-tab">
            <span style="cursor: pointer;" @click="toServiceStatus">{{ $t("serviceStatus") }} > </span>
            <span style="color: #000000;">{{ $t("records") }}</span>
          </div>
          <span class="title-black-54-600" style="margin-top: 20px;">{{ serviceName }}</span>
          <EmptyView v-if="list.length === 0" :title="$t('noRecord')"/>
          <div class="common-column-center" style="margin-top: 60px; align-self: stretch;">
            <div v-for="(item, index) in list" :key="index" class="item-root" :class="{ 'open': item.isOpened }">
              <div class="common-row-center" style="cursor: pointer;" @click="item.isOpened =!item.isOpened">
                <div style="display: flex; flex-direction: column; flex: 1;">
                  <span class="item-title">
                    {{ getServiceStatusName1(item) }}
                  </span>
                  <span class="item-time">
                    {{ dayjs(item.endTime ? item.endTime : item.startTime).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                </div>
                <img
                  :src="item.faultStatus === 1 ? require(`@/assets/images/icServiceN.svg`) : require(`@/assets/images/icServiceE.svg`)"
                  alt=""
                  class="service-status-icon"
                />
                <span class="item-status-text">
                  {{ getServiceStatusName2(item) }}
                </span>
                <img
                  :src="require(`@/assets/images/icArrowRight.svg`)"
                  alt=""
                  class="arrow-icon"
                  :class="{ 'rotate': item.isOpened }"
                />
              </div>
              <template v-if="item.isOpened">
                <template v-if="item.faultStatus === 1">
                  <div class="line"></div>
                  <div class="common-row-center">
                    <span class="item-time">
                      {{ `${dayjs(item.endTime).format("YYYY-MM-DD HH:mm:ss")}  ${$t('resolved')}` }}
                    </span>
                  </div>
                  <span class="item-reason">
                    {{ getRepairDescription(item) }}
                  </span>
                </template>
                <div class="line"></div>
                <div class="common-row-center">
                  <span class="item-time">
                    {{ `${dayjs(item.startTime).format("YYYY-MM-DD HH:mm:ss")}  ${getServiceStatusName(item)}` }}
                  </span>
                </div>
                <span class="item-reason">
                  {{ getFailureDescription(item) }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section style="background: rgba(157, 201, 60, 1); height: 105px;"/> -->
    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng" style="white-space: pre-wrap">
              {{ $t('footerTip1') }}
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                {{ $t('footerTip2') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { productServiceHistoryGet } from "@/api/getData.js";
import dayjs from "dayjs";
import EmptyView from "../components/emptyView.vue";

export default {
  name: "ServiceStatus",
  components: {EmptyView},
  data() {
    return {
      dayjs,
      list: [],
      serviceId: "",
      serviceName: "",
    };
  },
  methods: {
    getRepairDescription(item) {
      return localStorage.lang === "zh-CN" ? item.repairDescriptionCn : item.repairDescriptionEn;
    },
    getFailureDescription(item) {
      return localStorage.lang === "zh-CN" ? item.failureDescriptionCn : item.failureDescriptionEn;
    },
    getServiceStatusName(item) {
      return localStorage.lang === "zh-CN" ? item.serviceStatusNameCn : item.serviceStatusNameEn;
    },
    getServiceStatusName1(item) {
      if(item.faultStatus === 1) {
        return this.$t("resolved");
      }else {
        return this.getServiceStatusName(item);
      }
    },
    getServiceStatusName2(item) {
      if(item.faultStatus === 1) {
        return this.$t("serviceStatus1");
      }else {
        return this.getServiceStatusName(item);
      }
    },
    toServiceStatus() {
      this.$router.push({
        name: localStorage.lang === "zh-CN" ? "ZhServiceStatus" : "ServiceStatus",
      });
    },
    getList() {
      productServiceHistoryGet({
        serviceId: this.serviceId,
      }).then((res) => {
        if(res.data && res.data.code === 0){
          this.list = res.data.data;
        }
      });
    },
  },
  mounted() {
    this.serviceId = this.$route.query.serviceId;
    this.serviceName = this.$route.query.serviceName;
    this.getList();
  },
}
</script>

<style lang="less" scoped>
.title-tab {
  font-weight: 400;
  font-size: 18px;
  color: #999999;
  line-height: 21px;
}
.item-root {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #DDDDDD;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  // transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  // max-height: 124px; /* 初始高度 */
  margin-top: 20px;
}

.item-root.open {
    // max-height: 2000px; /* 大于内容的最大高度 */
}

.item-title {
  font-weight: 600;
  font-size: 26px;
  color: #000000;
  line-height: 40px;
  word-break: break-word;
}

.service-status-icon {
  width: 36px;
  height: 36px;
}

.item-status-text {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
  margin-left: 12px;
}

.arrow-icon {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  transition: transform 0.3s ease-out;
}

.arrow-icon.rotate {
    transform: rotate(90deg); /* 向下箭头 */
}

.line {
  height: 1px;
  background: #DDDDDD;
  margin: 30px 0;
}

.item-time {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  flex: 1;
}

.item-reason {
  font-weight: 400;
  font-size: 16px;
  color: #454545;
  line-height: 20px;
  margin-top: 22px;
  word-break: break-word;
}
</style>