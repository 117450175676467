<template>

    <main>
      <section class="terms">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1 class="terms-hdng">LEGAL TERMS OF SERVICE</h1>
              <p class="terms-desc mb-0">Last updated: 2024-02-19</p>
              <!-- <p class="terms-desc term-days">Effective date: 02-19- 2024</p> -->

              <h2 class="terms-list-hdng">NXCLOUD TERMS OF SERVICE</h2>
              <p class="terms-desc">
                Welcome, and thank you for your interest in the online services collectively known as NXCLOUD, along with any related websites, networks, applications, software and other services and related
documentation provided by NXCLOUD (collectively, the "<strong>Services</strong>"）. These Terms of Service are a
legally binding agreement between you and NXCLOUD regarding your use of the Services. For the
purposes of these Terms of Service, “<strong>NXCLOUD</strong>,” “<strong>we</strong>,” “<strong>our</strong>,” and "<strong>us</strong>" refer to the applicable
NXCLOUD contracting entity.
              </p>

              <p class="terms-desc">
                PLEASE READ THE FOLLOWING TERMS CAREFULLY. BY CLICKING “I ACCEPT,” BY DOWNLOADING OR OTHERWISE ACCESSING OR USING
THE SERVICES, YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A
CONDITION TO YOUR USE OF THE SERVICES, YOU AGREE TO BE BOUND BY, THE
FOLLOWING TERMS AND CONDITIONS.
              </p>

              <p class="terms-desc">
OUR WEBSITE AND SERVICES ARE ONLY AVAILABLE TO COMPANIES OR OTHER
ORGANISATIONS THAT ARE LEGALLY REGISTERED UNDER THE LAWS OF THEIR
DOMICILE AND HAVE THE CAPACITY AND ABILITY TO PERFORM THEIR
OBLIGATIONS UNDER THIS AGREEMENT.PLEASE DO NOT CONTIUE TO USE THE
SERVICES PROVIDED BY US IF YOU DO NOT MEET THESE QUALIFICATIONS.WE HAVE
THE RIGHT TO TERMINATE THE PROVISION OF SERVICES TO YOU AT ANY TIME.
              </p>

              <p class="terms-desc">
                IF YOU ARE NOT ELIGIBLE,OR DO NOT AGREE TO THE TERMS,THEN YOU DO NOT
HAVE NXCLOUD'S PERMISSION TO USE THE SERVICES.YOUR USE OF THE SERVICES, AND NXCLOUD's PROVISION OF THE SERVICES TO YOU, CONSTITUTES AN
AGREEMENT BY NXCLOUD AND BY YOU TO BE BOUND BY THESE TERMS.
              </p>

              <p class="terms-desc">
                In these Terms of Service we cover the following topics:
              </p>
              <ul class="article article-Registration">
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 1:DEFINITIONS
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 2: SCOPE
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 3: YOUR RIGHT AND OBLIGATIONS
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 4: NXCLOUD RIGHTS AND OBLIGATIONS
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 5: FEES AND PAYMENT
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 6: CONFIDENTIALITY AND PUBLICITY
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 7: INTELLECTUAL PROPERTY RIGHTS
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 8: PROMISE
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 9: INDEMNIFICATION AND DISCLAIMER
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 10: FORCE MAJEURE
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 11 : GENERAL
                </li>
                <li>
                  <img class="li-img" src="@/assets/images/arrow.svg" />
                  SECTION 12: CONTACT US
                </li>
              </ul>

              <h2 class="terms-list-hdng">SECTION 1:DEFINITIONS</h2>
              <p class="terms-desc">
                These Terms of Service in the agreement are defined and have the meaning as set out in this clause:
              </p>
              <p class="terms-desc">
                <strong>Agreement:</strong> these Terms of Service are a legally binding agreement between you and NXCLOUD
regarding your use of the Services.Each greement and/or "Order Form"entered into by and between
you and NXCLOUD, to which these Terms of Service shall apply.
              </p>
              <p class="terms-desc">
                <strong>Applicable Law:</strong> then-current national, local or other law, rule, regulation, enforceable regulatory
guidance, order, judgment, decree, or ruling in any jurisdiction in which you accesses and uses the
Services, including but not limited to, data protection and privacy regulations, guidelines, conditions, policy rules and/or regulations, guidelines, conditions of Operators applicable to the Service and/or
End User Service.
              </p>
              <p class="terms-desc">
                <strong>Customer:</strong> the Party with whom NXCLOUD enters into an agreement.More commonly referred to
in this agreement as "you".
              </p>
              <p class="terms-desc">
                <strong>Customer Materials:</strong> all information, data, content, and other materials, in any form or medium, that is provided by or on behalf of you to NXCLOUD.
              </p>
              <p class="terms-desc">
                <strong>NXCLOUD:</strong> the NXCLOUD group company entering into the agreement with you as identified in
the applicable Agreement.
              </p>
              <p class="terms-desc">
                <strong>NXCLOUD IP:</strong> the service, the software and the NXCLOUD Platform, and any improvements, modifications or enhancements to or derivative works of the foregoing, and all intellectual property
rights in and to any of the foregoing.
              </p>
              <p class="terms-desc">
                <strong>NXCLOUD Platform:</strong> NXCLOUD's computing environment designed to provide the Service and to
establish the connection between NXCLOUD and your.
              </p>
              <p class="terms-desc">
                <strong>Intellectual Property Rights:</strong> all intellectual property rights of any nature whatsoever throughout
the world and for the full duration of any and all intellectual property protection afforded to the same
including all: (a) patents, registered trademarks, service marks, copyright, designs and any and all
applications for registration of any of the same wheresoever made; (b) unregistered trademarks, service marks, designs, design right and copyright; and (c) know how, trade secrets howsoever
arising and any right or interest in any of the foregoing.
              </p>
              <p class="terms-desc">
                <strong>Effective Date:</strong> means the date on which a particular agreement comes into effect.
              </p>
              <p class="terms-desc">
                <strong>End User:</strong> a natural person or legal entity authorized by you to make use of the your end user
services.
              </p>
              <p class="terms-desc">
                <strong>End User Service:</strong> the service you provide to your end users.
              </p>
              <p class="terms-desc">
                <strong>Operator:</strong> a electronic communications service provider, or provider of over the top communication
services that provides (wireless) messaging, voice and data communication and other related services
to its customers including NXCLOUD, its subscribed end users and other Operators.
              </p>
              <p class="terms-desc">
                <strong>Personal Data:</strong> any information relating to an identified or identifiable natural person ("data subject"); an identifiable natural person is one who can be identified, directly or indirectly, in
particular by reference to an identifier such as a name, an identification number, location data, an
online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
              </p>
              <p class="terms-desc">
                <strong>Service(s):</strong> the service NXCLOUD provides to you under the agreement, which may include
electronic communications, professional services, providing access to applications as software as a
service, services that provide both platform services, including access to and use of the NXCLOUD
Platform, any application programming interface (NXCLOUD API) and/or communications services
used in connection with the NXCLOUD Platform or NXCLOUD APIs.
              </p>
              <p class="terms-desc">
                <strong>Working Day:</strong> Monday to Friday from 9 a.m. to 6.00 p.m., in accordance with the time zone per the
NXCLOUD entity providing the Service, excluding generally recognized public holidays.
              </p>

              <h2 class="terms-list-hdng">SECTION 2: SCOPE</h2>
              <p class="terms-desc">
                2.1 These Terms of Service apply to all agreements between NXCLOUD and you unless the Parties
have expressly agreed otherwise in writing.
              </p>
              <p class="terms-desc">
                2.2 The applicability of any general (purchasing) terms used by service, or any other terms and
conditions other than included in the agreement and these Terms of Service, is hereby expressly
excluded.
              </p>
              <p class="terms-desc">
                2.3 The Parties hereby agree that any electronic signature shall have the same legal validity and
enforceability as a manually executed signature to the fullest extent permitted by law. For the
purpose of these Terms of Service, any reference to "written" or in writing" means any form of
written communication, including, without limitation, electronic signatures, and any such written
communication may be transmitted by electronic transmission.
              </p>
              <p class="terms-desc">
                2.4 Applicable law applies to the end user services offered by customer to its end users in the country
where the end user service is delivered. You shall familiarize yourself with such applicable law
(including obtaining independent legal advice if necessary) applicable to the end user service. NXCLOUD makes no warranties and assumes no liability in this regard.
              </p>
              <p class="terms-desc">
                2.5 The NXCLOUD PRIVACY POLICY form an integral part of these Terms of Service and the
agreement between customer and NXCLOUD covering customers use of the services.
              </p>

              <h2 class="terms-list-hdng">SECTION 3: YOUR RIGHT AND OBLIGATIONS</h2>
              <p class="terms-desc">
                3.1 Registration and use
              </p>
              <p class="terms-desc">
                3.1.1 By completing the registration process or by actually using the Service in a manner permitted
by other platforms, you confirm that you are legal entity or other organisation with full legal capacity
and the capacity to act in accordance with civil law. If you do not have the above qualifications, please do not use the Service, otherwise you and your legal guardian will bear all consequences
arising therefrom, and NXCLOUD has the right to cancel (permanently freeze) your account and
claim damages from you and your legal guardian. If you register on behalf of a company or other
legal entity, or use the Services in a manner permitted by other platforms, you represent and warrant
that you have the right to bind such company or legal entity to the terms of this Agreement.
              </p>
              <p class="terms-desc">
                3.1.2 You shall provide the Registration Information as required by the Platform and ensure that the
Registration Information provided is true and valid. If any of your relevant Registration Information
changes, you shall update the relevant Registration Information within one business day of the
change. If your Registration Data is false, inaccurate, or not updated in a timely manner, NXCLOUD
has the right to suspend your Services, resulting in any loss or liability to you.
              </p>
              <p class="terms-desc">
                3.1.3 You agree that the account name, logo, profile and other information you register will not
contain any illegal or unfavourable information, that you will not impersonate any other person, that
you will not register on behalf of any other person without permission, that you will not register
accounts in a way that may mislead other users, and that you will not use a username that may
infringe the rights and interests of others (including, but not limited to, suspected infringement of
trademark rights or rights of publicity); otherwise, NXCLOUD has the right to refuse registration or
to suspend the service and revoke the account number, and you will be liable for any losses incurred
as a result.
              </p>
              <p class="terms-desc">
                3.1.4 You understand and agree that ownership of the registered account is vested in NXCLOUD. Upon completion of the registration, you will only receive the right to use the account. Otherwise, NXCLOUD has the right to revoke the account immediately and without notice, and you shall bear
the losses resulting from the deletion or loss of all data, information, etc. caused by your use of the
Service.
              </p>
              <p class="terms-desc">
                3.1.5 Upon successful registration, NXCLOUD will verify your identity using your account and
password. You are responsible for maintaining the confidentiality of your account and password and
are solely responsible for all activities that occur under your account and password. You agree to
notify the Platform immediately of any unauthorised use of your password or account, or any other
breach of security, and you agree and confirm that NXCLOUD shall not be liable for any direct or
indirect loss or damage caused by the above circumstances. Your account and password may not be
transferred, assigned or inherited in any way (except for property rights and interests related to the
account), unless otherwise provided by law or court order and with the consent of NXCLOUD.
              </p>
              <p class="terms-desc">
                3.1.6 You understand and agree that if you do not access your account for more than 6 consecutive
months, NXCLOUD has the right to recover the account for the purpose of website optimisation
management, and you shall bear the related expenses and responsibilities.
              </p>
              <p class="terms-desc">
                3.1.7 After an account has been terminated or cancelled in accordance with this agreement, NXCLOUD has the right to deal with the relevant content and information of the account, including
but not limited to deletion, and does not have to bear any liability towards the user.
              </p>
              <p class="terms-desc">
                3.2 Occupancy and access services
              </p>
              <p class="terms-desc">
                3.2.1 Admission: You shall register with NXCLOUD and submit the relevant materials as required
by the platform before you can complete the developer approval process. After successful admission, the account will be opened automatically.
              </p>
              <p class="terms-desc">
                3.2.2 Access to Services: You shall select and apply for the appropriate API Call Authorisation with
Communication Capability and confirm the appropriate Terms of Service. Once you have been
granted the appropriate communication capability authority after verification, you can log in to the
Administration Console to perform service management and other operations.
              </p>
              <p class="terms-desc">
                3.3 Specifications for use.
              </p>
              <p class="terms-desc">
                3.3.1 You will only use the Services for their intended and normal purpose and/or for the purposes
agreed and described in the Agreement. Without limitation, you may not: (i) reverse engineer or
otherwise attempt to gain unauthorised access to any component of the Service; ( Ⅱ ) resell the Service or otherwise use, or permit any other person to use, the Service for any purpose other than
for your benefit in accordance with the Agreement; (iii) interfere with or (attempt to) disrupt the
integrity or performance of the Service or any data or content contained in or transmitted by the
Service; (Ⅳ) collect any data from or about the Service, except for service features provided by
NXCLOUD and used expressly for such purposes; or (v) use the Service or any NXCLOUD
Confidential Information for benchmarking or competitive analysis of competitive or related
products or services, or to develop, market, license or sell any product, service or technology that
directly or indirectly competes with the Service.
              </p>
              <p class="terms-desc">
                3.3.2 You will take effective measures to prevent hacking, password theft, illegal page requests and
other illegal actions that endanger the information security of the communications business. You
shall properly keep the interface and account password of this service and ensure the security of the
terminal and network for your own use. All actions performed using the account password shall be
deemed to be your actions, and the consequences of the loss or theft of the account password and
network insecurity shall be borne by you.
              </p>
              <p class="terms-desc">
                3.3.3 You agree not to modify or interfere or attempt to modify or interfere in any way with the
Platform or any other NXCLOUD website or system or any part or function of the NXCLOUD
website, system or code provided to you. If you violate the above terms and conditions, NXCLOUD
has the right to take measures such as warning, limiting the service, taking the application offline, deleting the application, suspending or terminating the service.
              </p>
              <p class="terms-desc">
                3.3.4 You are legally responsible for your own actions in using the Platform Services and for the
rights and obligations between you and your clients. You shall independently develop and
independently operate the application results of the Platform Services. NXCLOUD shall not be
involved in the operation of your application, and you shall carry out the operation of the application
in accordance with the law and independently bear the corresponding legal liability. You agree to
indemnify and hold NXCLOUD and its affiliates and partners harmless from and against any and all
claims, demands, or losses by any third party resulting from or arising out of any violation of this
Agreement or the relevant Terms of Service.
              </p>
              <p class="terms-desc">
                3.3.5 You shall meet the technical and commercial requirements of NXCLOUD for the interface.
              </p>
              <p class="terms-desc">
                3.3.6 You may not sell, exchange, lend, sublet or sublicense the services, interfaces, data, etc. of this
Agreement, in whole or in part, to any third party.
              </p>
              <p class="terms-desc">
                3.3.7 You agree to abide by the platform rules, specifications, etc. formulated by NXCLOUD, and to
abide by the corresponding appendix according to the selected services.
              </p>
              <p class="terms-desc">
                3.3.8 You will not breach the Agreement or use any of the resources of the Platform in an unlawful
manner; you will not sell, transfer or assign the code, API (Application Programming Interface), development tools and other Platform service resources of the Platform to any entity or person; you
will only have the right to use the services of the Platform in a lawful manner in accordance with this
Agreement.
              </p>

              <h2 class="terms-list-hdng">SECTION 4: NXCLOUD RIGHTS AND OBLIGATIONS</h2>
              <p class="terms-desc">
                4.1 NXCLOUD has the right to modify and upgrade the Platform and other operations, and has the
right to formulate and modify the Platform and Service specifications. As soon as the specifications
are published on the websites of NXCLOUD and its affiliated companies, they become part of this Agreement and you must comply with them. Failure to do so will result in the automatic termination
of this Agreement.
              </p>
              <p class="terms-desc">
                4.2 NXCLOUD has the right to inspect and monitor your services for the purpose of preventing, detecting and investigating fraud, security threats, illegal activities or violations of agreements, policies or rules with you or your affiliates. NXCLOUD reserves the right to suspend or terminate
the Services provided to you at its sole discretion, whether or not you and your customers are using
the Services to engage in inappropriate activities, based on complaints from end users or third parties
and NXCLOUD's monitoring and inspection of the Services.
              </p>
              <p class="terms-desc">
                4.3 NXCLOUD has the right to change some or all of the functions of its services if necessary. If the
change of functions may cause an interruption or termination of the daily services, NXCLOUD will
notify you and complete the repair of basic functions as soon as possible. You may choose to
continue using the adjusted service features or to discontinue using the Platform Services.
              </p>
              <p class="terms-desc">
                4.4 Your application and the business you operate must not cause any actual or potential damage or
conflict of interest to NXCLOUD and its affiliates, otherwise NXCLOUD has the right to
immediately notify you and terminate all services provided by the Platform to users without any
legal liability or compensation, and NXCLOUD reserves the right to pursue your corresponding
liability.
              </p>

              <h2 class="terms-list-hdng">SECTION 5: FEES AND PAYMENT</h2>
              <p class="terms-desc">
                5.1 Fees. You agree to pay the fees set forth in the applicable invoice.
              </p>
              <p class="terms-desc">
                5.2 Taxes and Communications Surcharges
              </p>
              <p class="terms-desc">
                5.2.1 Taxes. All fees are exclusive of any applicable taxes, levies, duties, or other similar exactions
imposed by a legal, governmental, or regulatory authority in any applicable jurisdiction, including, without limitation, sales, use, value-added, consumption, communications, or withholding taxes
(collectively, “Taxes”）. You will pay all Taxes in connection with this Agreement, excluding any
taxes based on NXCLOUD's net income, property, or employees. If you are required by applicable
law to withhold any Taxes from payments owed to NXCLOUD, you will reduce or eliminate such
withheld Taxes upon receipt of the appropriate tax certificate or document provided by NXCLOUD. You will provide NXCLOUD with proof of payment of any withheld Taxes to the appropriate
authority. Taxes will be shown as a separate line item on an invoice.
              </p>
              <p class="terms-desc">
                5.2.2 Exemption. If you are exempt from paying certain Taxes, you will provide the necessary
exemption information as requested by NXCLOUD or a valid exemption certificate issued by the
appropriate authority via e-mail to <a href="mailto:billing@nxcloud.com">billing@nxcloud.com</a>. You will be exempt on a going-forward
basis once NXCLOUD has approved your exemption request. If the appropriate authority determines, at any time, that you are not exempt from paying any Taxes, you will promptly pay such Taxes to
NXCLOUD, plus any applicable interest or penalties.
              </p>
              <p class="terms-desc">
                5.3 Payment Terms. Except as otherwise expressly set forth herein, payment obligations are
non-cancelable and fees, Taxes (collectively, “Fees”）, once paid, are non-refundable. Except as
otherwise set forth in the applicable agreement(s).
              </p>
              <p class="terms-desc">
                5.4 Credit Card. If you elect to add funds to your account by credit card and use such funds to pay
the Fees due, you are responsible for ensuring such funds cover the Fees due. If your account does
not have sufficient funds or your credit card declines a charge for the Fees due, NXCLOUD may suspend the provision of the Services to all of your accounts until the Fees due are paid in full. You
are prohibited from creating new accounts until the Fees due are paid in full.
              </p>
              <p class="terms-desc">
                5.5 Collection. Any company in the NXCLOUD group has the right to collect your payment.
              </p>

              <h2 class="terms-list-hdng">SECTION 6: CONFIDENTIALITY AND PUBLICITY</h2>
              <p class="terms-desc">
                6.1 As used herein, “Confidential Information” means any information that one Party (the
"Disclosing Party"） provides to the other Party (the "Receiving Party"） in connection with the
Agreement, whether orally or in writing, that is designated as confidential or that reasonably should
be considered to be confidential given the nature of the information and/or the circumstances of
disclosure. For clarity, the Service will be deemed Confidential Information of NXCLOUD However, Confidential Information will not include any information or materials that: (i) were, at the date of
disclosure, or have subsequently become, generally known or available to the public other than as a
result of a breach of the confidentiality terms of the Agreement; (ii) were rightfully known by the
Receiving Party prior to receiving such information or materials from the Disclosing Party; (iii) are
rightfully acquired by the Receiving Party from a third party who has the right to disclose such
information or materials without breach of any confidentiality obligation to the Disclosing Party; or
(iv) are independently developed by or for the Receiving Party without use of or access to any
Confidential Information of the Disclosing Party. Without limiting the foregoing, nothing in these
Terms and Conditions will limit or restrict NXCLOUD's ability to use or disclose any general
know-how, experience, concepts and/or ideas that NXCLOUD or its personnel acquire or obtain in
connection with the performance of NXCLOUD's obligations hereunder.
              </p>
              <p class="terms-desc">
                6.2 The Receiving Party will maintain the Disclosing Partys Confidential Information in strict
confidence and will not use the Confidential Information of the Disclosing Party except as necessary
to perform its obligations or enforce its rights under these Terms and Conditions. The Receiving
Party will not disclose any Confidential Information of the Disclosing Party, except (i) to those
employees, representatives, or contractors of the Receiving Party who have a bona fide need to know
such Confidential Information and who are bound by written agreement with use and nondisclosure
restrictions at least as protective as those set forth in these Terms and Conditions, or (ii) as such
disclosure may be required by the order or requirement of a court, administrative agency, stock
market or governmental body, subject to the Receiving Party providing to the Disclosing Party
reasonable written notice to allow the Disclosing Party to seek a protective order or otherwise contest
the disclosure.
              </p>
              <p class="terms-desc">
                6.3 Each Party's obligations with regard to Confidential Information are effective as of the Effective
Date and will expire three (3) years after termination of the Agreement; provided, however, with
respect to any Confidential Information that constitutes a trade secret (as determined under
Applicable Law), such obligations will survive the termination or expiration of the Agreement for as
long as such Confidential Information remains subject to trade secret protection under Applicable
Law.
              </p>
              <p class="terms-desc">
                6.4 These Terms of Service of the Agreement will constitute Confidential Information of each Party, but may be disclosed on a confidential basis to a Partys advisors, attorneys, actual or bona fide
potential acquirers, investors or other sources of funding (and their respective advisors and attorneys)
for due diligence purposes.
              </p>
              <p class="terms-desc">
                6.5 You hereby grant NXCLOUD the right and permission to use your name, trade mark(s) and company logo in NXCLOUD's marketing, sales, financial and public relations materials and other
communications to identify you as a customer of NXCLOUD. Subject to NXCLOUD's prior written
consent, NXCLOUD hereby grants you the express right to use NXCLOUD's name and logo solely
to identify NXCLOUD as a provider of services to you. All goodwill and enhanced reputation
generated by your use of NXCLOUD's name and logo shall inure to the exclusive benefit of
NXCLOUD; and all goodwill and enhanced reputation generated by NXCLOUD's use of your name
and logo shall inure to the exclusive benefit of NXCLOUD. Except as expressly provided herein, neither party shall use the name(s), trademarks, logos, URLs or specifications of the other party
without the prior written consent of the other party.
              </p>

              <h2 class="terms-list-hdng">SECTION 7: INTELLECTUAL PROPERTY RIGHTS</h2>
              <p class="terms-desc">
                7.1 NXCLOUD or other rights holders lawfully own the intellectual property rights to all content on
the Platform, including but not limited to works, images, archives, information, materials, website
structure, website image arrangement and page design. No one may use, modify, reproduce, publicly
display, alter, distribute, or publicly perform the Platform programs or content without the written
permission of NXCLOUD or other rights holders.
              </p>
              <p class="terms-desc">
                7.2 The intellectual property rights of an application independently developed by a developer belong
to the owner; the owner agrees to grant NXCLOUD and its affiliates a free, perpetual, irrevocable, non-exclusive, transferable and assignable licence to use, access and demonstrate the products of the
NXCLOUD application development platform worldwide. This licence shall not be affected by the
termination of the cooperation between the parties or by the termination of this Agreement.
              </p>
              <p class="terms-desc">
                7.3The systems, codes, data, trademarks, etc. involved in the project cooperation under this
Agreement are the property of both parties in accordance with the law. The other party shall not use, modify, copy, publicly disclose or distribute such systems, codes, data, trademarks, etc. without the
written consent of the rightful owner. Failure to do so shall entitle the Proprietor to terminate this
Agreement with immediate effect and to claim compensation from the other party for all losses
(including, but not limited to, investigation and evidence collection costs, notarisation costs, legal
fees, etc.).
              </p>
              <p class="terms-desc">
                7.4 You may not reverse engineer, disassemble, reconstruct, decompile, translate, modify, copy or
create derivative works of NXCLOUD based on the technical interface, source code, algorithms, etc. that are open to cooperation in this project without express permission.
              </p>

              <h2 class="terms-list-hdng">SECTION 8: PROMISE</h2>
              <p class="terms-desc">
                Anti-Corruption and International Trade Laws. Each party (a) warrants that it will comply with all
applicable anti-corruption, anti-money laundering, economic and trade sanctions, export control and
other international trade laws, regulations and governmental orders (collectively, "Anti-Corruption
and Trade Laws") that are directly or indirectly applicable to the jurisdiction in which the Services
are provided (including, without limitation, the United States), and (b) represents that it has not made, promised or authorised any payment or other thing of value in violation of any Anti-Corruption and
Trade Laws. You will immediately notify NXCLOUD in writing of any actual or potential violation of anti-corruption and trade laws in connection with the use of the Services and will take all
appropriate steps to remedy or terminate such violation, including any steps requested by
NXCLOUD. If applicable, you represent that you have obtained and warrant that you will continue
to obtain all licences or other authorisations required to export, re-export or transfer the Services. Each party represents and warrants that it (and, in the case of you, your end users) is not on any
governmental prohibited, denied or unverified parties, sanctions, debarment or exclusion lists, or lists
of restricted parties in connection with export controls (collectively, "Sanctions Lists"). If You are
placed on a Sanctions List, You will immediately (i) cease using the Services, and if Your End Users
are placed on a Sanctions List, You will immediately (ii) terminate Your End Users' access to the
Services.
              </p>

              <h2 class="terms-list-hdng">SECTION 9: INDEMNIFICATION AND DISCLAIMER</h2>
              <p class="terms-desc">
                9.1 By Customer
              </p>
              <p class="terms-desc">
                9.1.1 Client acknowledges that access to the internet, telecommunication networks and other
communication media is subject to uncertainties, including, but not limited to, availability of services, the reliability of transmission, authorizations, authenticity and data security. NXCLOUD does not
warrant that the Services are, or will be completely error-free, bug-free or uninterrupted. NXCLOUD
shall not be liable for any stoppages, slowdowns, interruptions of the Service, performance problems
or any other problems that are due to circumstances beyond its control, including, but not limited to, disturbance of telephone and/or telecom transmissions and/or Operator networks, deficiencies or
limitations in Client's hardware, systems, software, or processes, deficiencies or limitations in
internet or wireless connections. In the event of an error, bug or interruption, NXCLOUD shall use
its reasonable efforts to restore the Service in accordance with proper practices recognized in the
industry.
              </p>
              <p class="terms-desc">
                9.1.2 Customer agrees to defend, indemnify, and hold harmless NXCLOUD, its Affiliates, and each
of their respective employees, licensors, contractors, directors, officers and representatives, from and
against any damages, liabilities, claims, demands, obligations, losses, fines, penalties, and expenses
(including reasonable attorneys fees) (collectively, “Losses”） incurred in connection with claims
made or brought by a third party arising from or relating to: (i) use of the Services in a manner not
authorized by this Agreement, including violations applicable law, by Customer, its Affiliates, or its
or their Authorized Users, employees, agents, or subcontractors; (ii) claims relating to Customer Data;
or (iii) gross negligence, fraud or willful misconduct of Customer, its Affiliates, or its or their
employees, agents or subcontractors.
              </p>
              <p class="terms-desc">
                9.2 By NXCLOUD
              </p>
              <p class="terms-desc">
                NXCLOUD agrees to defend, indemnify and hold harmless Customer from and against any Losses
incurred in connection with claims made or brought by a third party arising from or relating to (i)
infringement of third-party Intellectual Property Rights due to Customers authorized use of the
Services (other than Customer Data); or (ii) gross negligence, fraud or willful misconduct of
NXCLOUD or its employees, agents, or subcontractors. Notwithstanding any of the foregoing, NXCLOUD shall have no liability for Losses arising out of (x) any combination, operation, or use of
the Services with any hardware, software, or services not provided by NXCLOUD, except to the
extent such combination, operation, or use is necessary for the use of the Services; or (y) use of any sample or reference code made available by NXCLOUD or any third party, via the NXCLOUD
Website or otherwise.
              </p>
              <p class="terms-desc">
                9.3 Procedure
              </p>
              <p class="terms-desc">
                The total liability for NXCLOUD, if any,arising out or in connection with this Agreement (whether
arising in contract, tort or otherwise)shall not exceed an amount of fees paid hereunder during the six
(6) month period immediately prior to the event that gave rise to the liability.
              </p>
              <p class="terms-desc">
                9.4 Disclaimer of Warranties
              </p>
              <p class="terms-desc">
                EXCEPT AS SPECIFICALLY SET FORTH IN THIS AGREEMENT, SALES ORDER, OR
APPLICABLE SERVICE LEVEL AGREEMENT, TO THE MAXIMUM EXTENT PERMITTED
BY APPLICABLE LAW, THE SERVICES AND NXCLOUD CONTENT ARE PROVIDED "AS
IS" ， “AS AVAILABLE,” AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, QUALITY, AND FITNESS FOR A
PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF
PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. NXCLOUD, AND NXCLOUD's SUPPLIERS, PARTNERS, AND LICENSORS, AND EACH OF
NXCLOUD's AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AND
AGENTS, DO NOT WARRANT (AND HEREBY EXPRESSLY DISCLAIM ALL WARRANTIES)
THAT: (I) THE SERVICES (OR ANY MOBILE OPERATORS) WILL BE SECURE OR
AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, (II) ANY DEFECTS OR ERRORS
WILL BE CORRECTED, (III) THE CONTENT ON THE NXCLOUD WEBSITES OR SERVICES
(OR ANY THIRD PARTY SITES OR SERVICES LINKED THERETO) IS ACCURATE, ERROR-FREE, OR COMPLETE, OR (IV) THE RESULTS OF USING THE SERVICES WILL
MEET CUSTOMERS REQUIREMENTS. NXCLOUD DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY CONTENT OF, COMMUNICATION
BY, OR PRODUCT OR SERVICE ADVERTISED OR OFFERED BY, A THIRD PARTY
THROUGH THE SERVICES, AND NXCLOUD WILL NOT BE A PARTY TO OR IN ANY WAY
BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN CUSTOMER AND
THIRD PARTIES. EXCEPT AS OTHERWISE AGREED IN A BUSINESS ASSOCIATE
AGREEMENT SIGNED BY NXCLOUD, NXCLOUD MAKES NO REPRESENTATION OR
WARRANTY THAT THE SERVICES OR THEIR USE WILL COMPLY WITH HIPAA OR WILL
RENDER ANY PARTY COMPLIANT WITH HIPAA, AND IS RELEASED FROM ANY
LIABILITY FOR ITS ACTS OR OMISSIONS RELATING TO HIPAA.
              </p>

              <h2 class="terms-list-hdng">SECTION 10: FORCE MAJEURE</h2>
              <p class="terms-desc">
                If the performance of this Agreement is impossible, unnecessary or pointless due to force majeure or
other unforeseen circumstances, the party suffering from the force majeure or unforeseen
circumstances shall not be liable. Force majeure and unforeseen events are objective events that are
unforeseeable, unavoidable or insurmountable and have a significant impact on one or both parties, including but not limited to natural disasters such as floods, earthquakes, epidemics, etc., as well as
social events such as wars, riots, government actions, disruption of main telecommunications lines, hacking, network congestion, technical adjustment of telecommunications departments, government control, etc.
              </p>

              <h2 class="terms-list-hdng">SECTION 11 : GENERAL</h2>
              <p class="terms-desc">
                <strong>11.1 Independent Contractors.</strong> The relationship of the parties established by these Terms is that of
independent contractors, and nothing contained in these Terms should be construed to give either
party the power to: (i) act as an agent; or (ii) direct or control the day-to-day activities of the other. Financial and other obligations associated with each partys business are the sole responsibility of that
party and neither party has authority to bind the other party.
              </p>
              <p class="terms-desc">
                <strong>11.2 Non-Assignability and Binding Effect.</strong> Neither party may assign or otherwise transfer, by
operation of law or otherwise, its rights or obligations under these Terms without the prior written
consent of the other party, except that NXCLOUD may freely assign or otherwise transfer these
Terms without your consent: (i) in connection with a merger, acquisition or sale of all or substantially
all of NXCLOUD’s assets; or (ii) to any Affiliate or as part of a corporate reorganization. Upon such
assignment or transfer taking effect, the successor or permitted assigns (as the case may be) shall
assume assignor/transferors liability and assignor/transferor is released from the same. Any
attempted assignment or transfer in violation of the foregoing restriction will be void. Subject to the
foregoing, these Terms will be binding upon and inure to the benefit of the parties and their
successors and permitted assigns.
              </p>
              <p class="terms-desc">
                <strong>11.3 Consent to Electronic Communications.</strong> By using the Services, you consent to receiving
certain electronic communications from us as further described in NXCLOUD Privacy Policy. Please
read our Privacy Policy to learn more about our electronic communications practices. You agree that
any notices, agreements, disclosures, or other communications that we send to you electronically, whether by e-mail, through the Services platform, or otherwise, will satisfy any legal communication
requirements, including that those communications be in writing.
              </p>
              <p class="terms-desc">
                <strong>11.4 Force Majeure.</strong> If the performance of these Terms is prevented, delayed, hindered or restricted, or NXCLOUD breaches these Terms due to an event of force majeure, including but not limited to: (i)
natural disasters; (ii) acts of government; (iii) promulgation or change of laws, regulations or policies
(including Trade Laws, sanctions, restrictive measures or regulations); (iv) strikes or unrest; or (v)
any significant change of circumstances (including changes in applicable laws which would render
provision of Services potentially illegal or different from that contemplated by the parties at time of
the acceptance of these Terms or first download, install, access, or use the Services), foreseeable or
otherwise, in no case shall NXCLOUD be liable for the breach of these Terms, or be otherwise liable
for any such failure or delay in the performance of such obligations. If any of the abovementioned
events persists for more than 15 calendar days, NXCLOUD may terminate these Terms, without
assuming any liability, by immediate written notice to you.
              </p>
              <p class="terms-desc">
                11.5 This Terms of Service and any dispute or claim arising out of or in connection with it or its
subject matter or formation (including non-contractual disputes or claims) shall be governed by and
construed and enforced in accordance with the laws of Singapore. Any dispute first arising in relation to these Terms of Service and all claims and matters will be
resolved primarily through negotiation between the Parties. If the resolution is not reached through
negotiation after thirty (30) working days from the date of commencing amicable dispute resolution, one party may submit the dispute to be settled by the The Singapore International Arbitration Centre(SIAC).The proceedings shall be held in English. The arbitrators decision shall be final and
binding.
              </p>
              <p class="terms-desc">
                <strong>11.6 Waiver and Severability.</strong> The waiver by either party of any breach of these Terms does not
waive any other breach. Neither party will be treated as having waived any rights by not exercising
(or delaying the exercise of) any rights under these Terms. If any part of these Terms isunenforceable, the remaining portions of these Terms will remain in full force and effect.
              </p>
              <p class="terms-desc">
                <strong>11.7 No Third-Party Beneficiaries.</strong> These Terms are not intended to confer any benefits on any
third party except to the extent that it expressly states that it does. End Users are not third party
beneficiaries to these Terms.
              </p>
              <p class="terms-desc">
                <strong>11.8 Modification of these Terms, the Privacy Policy and the Cookies Policy.</strong> NXCLOUD may
amend these Terms, including the Additional Terms, from time to time by posting updated versions
to the NXCLOUD Cloud site. Unless specifically provided in these Terms or the Additional Terms, or otherwise indicated by NXCLOUD, the amended terms will take effect within 3 calendar days
after they are posted. Notwithstanding the foregoing, any changes relating to NXCLOUD's Services
or product functionalities shall take effect immediately. NXCLOUD will use reasonable efforts to
notify you of the changes, but you are responsible for periodically checking these Terms, including
the Additional Terms, for any modifications. Your continued use of the Services constitutes your
acceptance of any amended Terms. Amended terms are not applicable retroactively.
              </p>
              <p class="terms-desc">
                <strong>11.9 Language.</strong> All communications and notices in relation to these Terms shall be made or given in
either English or Chinese. Notwithstanding the foregoing, to the extent any translations of these
Terms are made, the English version shall prevail.
              </p>
              <p class="terms-desc">
                <strong>11.10 Notice.</strong> Any notice required or permitted to be given under these Terms will be effective if it is
in writing and sent by certified or registered mail, or insured courier, return receipt requested, to the
appropriate party at the address set forth above (in the case of NXCLOUD) and any address
registered with us (in your case) and with the appropriate postage affixed. Either party may change
its address for receipt of notice by notice to the other party in accordance with this Section. Notwithstanding the foregoing, any notices, communications, or disclosures sent electronically by
NXCLOUD through email, the platform for the Services or otherwise, shall be deemed a valid and
binding notice required or permitted to be given under these Terms.
              </p>

              <h2 class="terms-list-hdng">SECTION 12: CONTACT US</h2>
              <p class="terms-desc">
                If you have any questions, comments or suggestions regarding this privacy policy, you can contact us
by Email: <a href="mailto:compliance@nxcloud.com">compliance@nxcloud.com</a>, Telephone Number :400-7800-900, we will respond to your
request within 15 days.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="cts">
        <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
        <div class="container">
          <div class="row row-cts">
            <div class="col-md-8">
              <h3 class="archived-hdng cts-hdng">
                Start offering your customers a better service experience
              </h3>
            </div>

            <div class="col-md-4">
              <div class="cts-btn">
                <router-link to="/sign-up" class="btn btn-success"> 
                    Get Started
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>

<script>
  export default {
      name: 'TermsAndServices'
  }
</script>
<style scoped>
.li-img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>

    