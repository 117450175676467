<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">全渠道触达，<br />驱动企业增长</h1>
                  <p class="cntnt">通过全渠道客户互动沟通，提升营销转化。</p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/product_customer_engagement_banner.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt="Product_group"
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">我们的优势</h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>全渠道服务</strong>
                      <span
                        >集成短信、语音、WhatsApp等能力，实现一站式多渠道客户服务。</span
                      >
                    </li>
                    <li>
                      <strong>定制化方案</strong>
                      <span
                        >具备多年架构和方案设计经验，能为客户提供定制化解决方案。</span
                      >
                    </li>
                    <li>
                      <strong>全场景应用</strong>
                      <span
                        >可广泛应用于在线聊天、客服电话、消息通知等丰富的应用场景。</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">一站式整合营销服务</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#NXLink"
                  type="button"
                  role="tab"
                  aria-controls="NXLink"
                  aria-selected="true"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/NXLink.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> 数字营销 </strong>
                  </div>
                  <span>
                    支持全球短信、语音、WhatsApp、Viber等服务能力。
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#AICC"
                  type="button"
                  role="tab"
                  aria-controls="AICC"
                  aria-selected="false"
                >
                  <div class="icon_text">
                    <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/AICC.svg"
                        alt="icon"
                      />
                    </i> -->
                    <strong> 呼叫中心</strong>
                  </div>
                  <span>
                    融合智能语音技术，帮助企业快速搭建海外呼叫中心。</span
                  >
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="NXLink"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Attentive_customer_care.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">客服对话</h4>
                    <p class="cntnt">
                      可与客户实时开展消息对话，<br />及时解决客户咨询与疑虑，<br />构建便捷的沟通桥梁和窗口。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/After_sales_care.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">售后服务</h4>
                    <p class="cntnt">
                      推送产品说明、交易订单、物流信息等售后通知信息，为用户提供极致的售后服务体验，打造差异化优势。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Technology_driven_retargeting.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">二次营销</h4>
                    <p class="cntnt">
                      向老客户发送优惠券、复购折扣券、新产品福利等营销类信息，激活沉睡用户，促进二次转化，并沉淀企业私域流量。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="AICC"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Faster_better_service.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">客户服务</h4>
                    <p class="cntnt">
                      用于海外企业客服热线，供企业为客户及时解答各类咨询与投诉，为用户提供周到满意的服务，提升企业品牌形象。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Smarter_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">智能营销</h4>
                    <p class="cntnt">
                      可用于配合传统电话销售业务的开展，<br />通过智能外呼进行公司产品与服务推广，<br />高效、便捷完成营销转化。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
    
<script>
export default {
  name: "ZhProductCustomerEngagement",
};
</script>