<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-11 col-xl-11">
            <div class="row row_sign_up">
              <div class="col-12 col-xl-6 col-md-12 d-flex col-sign-up">
                <div class="inner">
                  <h1
                    class="hdng wow animted fadeInUp lg_size"
                    style="animation-delay: 0s"
                  >
                    重设密码
                  </h1>
                  <p
                    class="cntnt wow animted fadeInUp"
                    style="animation-delay: 0.2s"
                  ></p>
                </div>

                <section class="contact_sec">
                  <div class="form_wrap">
                    <form id="contactForm">
                      <div class="row">
                        <div class="col-xl-9 col-lg-12">
                          <div class="form_field">
                            <input
                              type="tel"
                              class="form_control"
                              name="phone"
                              id="phone"
                              placeholder="手机号码（国家代码+手机号码）/ 电子邮件"
                              v-model="forgotPassData.formData.email"
                              @input="checkUserEmail"
                              @blur="getCustomerByPhone"
                            />
                            <div
                              v-if="!forgotPassData.errors.showErrorEmail"
                              class="errorInput"
                            >
                              请输入手机号码/电子邮箱
                            </div>
                            <div
                              v-if="
                                forgotPassData.errors.showErrorEmail &&
                                userError
                              "
                              class="errorInput"
                            >
                              {{ userError }}
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-7 col-lg-10 col-9">
                          <div class="form_field">
                            <input
                              type="text"
                              class="form_control"
                              name="phone"
                              id="phone"
                              placeholder="输入验证码"
                              v-model="forgotPassData.formData.verifyCode"
                              @input="checkUserOtp"
                            />
                            <div
                              v-if="!forgotPassData.errors.showErrorOtp"
                              class="errorInput"
                            >
                              输入验证码
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-3">
                          <div class="form_field">
                            <button
                              id="captcha-btn"
                              v-show="!isEmail"
                              :class="{
                                disabled: forgotPassData.errors.isOtpClick || sendBtn,
                              }"
                              class="otpButton"
                              @click="sendOtp"
                            >
                              <span v-show="show">验证码</span>
                              <span v-show="!show">{{ count }}s</span>
                            </button>
                            <button
                              id="captcha-btn1"
                              v-show="isEmail"
                              :class="{
                                disabled:
                                  forgotPassData.errors.isOtpClick || sendBtn,
                              }"
                              class="otpButton"
                              @click="sendEmailCode"
                            >
                              <span v-show="show">OTP</span>
                              <span v-show="!show">{{ count }}s</span>
                            </button>
                            <!-- <div class="loader">
                              <div class="tm-ring"><div></div><div></div><div></div><div></div></div>
                            </div> -->
                          </div>
                        </div>
                        <div
                          class="col-12 col-xl-9 col-lg-12 mt-minus"
                          v-if="forgotPassData.errors.messageAfterOtp"
                        >
                          <div
                            :class="{
                              error: forgotPassData.errors.messageAfterOtp,
                            }"
                            class="messageAfterSubmit"
                          >
                            {{ forgotPassData.errors.messageAfterOtp }}
                          </div>
                        </div>
                        <div class="col-xl-9 col-lg-12">
                          <div class="form_field">
                            <div class="password-root">
                              <input
                                :type="isShowPwd ? 'text' : 'password'"
                                class="form_control"
                                name="phone"
                                id="phone"
                                placeholder="输入新密码"
                                autocomplete="new-password"
                                v-model="forgotPassData.formData.changePassword"
                                @input="checkUserPass"
                              />
                              <img class="password-eye" 
                              :src="isShowPwd ? require(`@/assets/images/eye_open.svg`) : require(`@/assets/images/eye_close.svg`)" 
                                alt="eye"
                                @click="isShowPwd = !isShowPwd" />
                            </div>
                            <div
                              v-if="!forgotPassData.errors.showErrorPassword"
                              class="errorInput"
                            >
                              输入新密码
                            </div>
                            <div
                              v-if="!forgotPassData.errors.showErrorPassword2"
                              class="errorInput"
                            >
                              请设置新密码 8~20个字，包含字母和数字
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-9 col-lg-12">
                          <div class="form_field">
                            <div class="password-root">
                              <input
                                :type="isShowConfirmPwd ? 'text' : 'password'"
                                class="form_control"
                                name="phone"
                                id="phone"
                                autocomplete="new-password"
                                placeholder="再次输入新密码"
                                v-model="confPass"
                                @input="checkUserConfPass"
                              />
                              <img class="password-eye" 
                              :src="isShowConfirmPwd ? require(`@/assets/images/eye_open.svg`) : require(`@/assets/images/eye_close.svg`)" 
                                alt="eye"
                                @click="isShowConfirmPwd = !isShowConfirmPwd" />
                            </div>
                            <div
                              v-if="
                                !forgotPassData.errors.showErrorConfPassword
                              "
                              class="errorInput"
                            >
                              再次输入新密码
                            </div>

                            <div
                              v-if="forgotPassData.errors.passwordMatch"
                              class="error messageAfterSubmit"
                            >
                              密码不一致
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-9 col-lg-12">
                          <div class="submit_wrap">
                            <div class="submitWrapper signUp">
                              <input
                                type="submit"
                                value="确认
"
                                @click="submitForgotForm"
                              />
                              <!-- Loader -->
                              <div
                                class="loader"
                                v-if="forgotPassData.errors.isForgotSubmit"
                              >
                                <div class="tm-ring">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>

                            <div
                              v-if="forgotPassData.errors.messageAfterSubmit"
                              :class="success ? 'success' : 'error'"
                              class="messageAfterSubmit"
                            >
                              {{ forgotPassData.errors.messageAfterSubmit }}
                            </div>
                            <p class="info">
                              没有帐户？
                              <router-link to="/zh-sign-up">
                                <u>立即注册</u>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </div>

              <div class="col-12 col-xl-6 col-md-12 mt-4 mt-md-0">
                <img
                  src="../../assets/images/login_banner.svg"
                  alt=""
                  class="wow animted fadeInUp"
                  style="animation-delay: 0s"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng" style="animation-delay: 0s">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import Captcha from "@/utils/aliyunCaptcha";
import util from "@/libs/util.js";
export default {
  name: "ZhForgetPassword",
  data() {
    return {
      isShowPwd: false,
      isShowConfirmPwd: false,
      confPass: "",
      success: false,
      forgotPassData: {
        errors: {
          showErrorEmail: true,
          showErrorOtp: true,
          showErrorPassword: true,
          showErrorPassword2: true,
          showErrorConfPassword: true,
          isForgotSubmit: false,
          isOtpClick: false,
          isOtpSubmit: false,
          messageAfterOtp: "",
          messageAfterSubmit: "",
          passwordMatch: false,
        },
        formData: {
          email: "",
          verifyCode: "",
          changePassword: "",
        },
      },
      show: true,
      sendBtn: true,
      timer: null,
      count: 0,
      userError: "",
      userInfo: "",
    };
  },
  mounted() {
    this.initCaptcha();
  },
  computed: {
    isEmail() {
      return this.forgotPassData.formData.email.includes("@");
    }
  },
  methods: {
    checkUserEmail() {
      if (!this.forgotPassData.formData.email.trim()) {
        this.forgotPassData.errors.showErrorEmail = false;
      } else {
        this.forgotPassData.errors.showErrorEmail = true;
      }
    },
    checkUserOtp() {
      if (!this.forgotPassData.formData.verifyCode.trim()) {
        this.forgotPassData.errors.showErrorOtp = false;
      } else {
        this.forgotPassData.errors.showErrorOtp = true;
      }
    },
    checkUserPass() {
      this.forgotPassData.errors.showErrorPassword = true;
      this.forgotPassData.errors.showErrorPassword2 = true;

      if (!this.forgotPassData.formData.changePassword.trim()) {
        this.forgotPassData.errors.showErrorPassword = false;
        return;
      } else {
        this.forgotPassData.errors.showErrorPassword = true;
      }

      const passwordRegex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/;
      if (!passwordRegex.test(this.forgotPassData.formData.changePassword)) {
        this.forgotPassData.errors.showErrorPassword2 = false;
      } else {
        this.forgotPassData.errors.showErrorPassword2 = true;
      }
    },
    checkUserConfPass() {
      if (!this.confPass.trim()) {
        this.forgotPassData.errors.showErrorConfPassword = false;
      } else {
        this.forgotPassData.errors.showErrorConfPassword = true;
      }
    },
    sendOtp(event) {
      event.preventDefault();
      //  check if fields are empty
      // if (!this.forgotPassData.formData.email.trim()) {
      //   this.forgotPassData.errors.showErrorEmail = false;
      //   return;
      // }
      // this.forgotPassData.errors.isOtpSubmit = true;
      // axios
      //   .get(
      //     `https://testdid.nxcloud.com/register/mailForgetPassword/?email=${this.forgotPassData.formData.email.trim()}`,
      //     {
      //       headers: {
      //         "Content-Language": "EN",
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     console.log(response);
      //     // this.signUpFormData.errors.showErrorTermsCheck = true;
      //     // this.signUpFormData.errors.isLoginSubmit = false;
      //     this.forgotPassData.errors.messageAfterOtp = response.data.msg;
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    },
    initCaptcha() {
      Captcha.initAliyunCaptcha({
        button: "#captcha-btn",
        // language: "en-US",
        captchaVerifyCallback: async (captchaVerifyParam) => {
          if (this.timer) return false;
          const res = await Captcha.verify({
            captchaVerifyParam,
            phone: this.forgotPassData.formData.email,
            type: 4,
          }, "ZH");
          const result = res.data.data;
          console.log(result);
          const verifyResult = {
            captchaResult: result.captchaResult, // 验证码验证是否通过，boolean类型，必选
            bizResult: result.sendResult, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
          };
          this.bizResult = {
            success: result.sendResult,
            msg: result.sendError,
          };
          return verifyResult;
        },
        onBizResultCallback: (bizResult) => {
          if (!bizResult) {
            // this.$Message.error(this.bizResult.msg);
            return;
          }
          // this.$Message.success("Send Successfully");
          const TIME_COUNT = 60;
          this.count = TIME_COUNT;
          this.show = false;
          this.sendBtn = true;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
              this.sendBtn = false;
            }
          }, 1000);
        },
      });
    },
    getCustomerByPhone() {
      if (this.forgotPassData.formData.email.includes("@")) {
        this.userError = "";
        this.sendBtn = false;
        return;
      }
      if (!this.forgotPassData.formData.email) {
        this.userError = "";
        this.sendBtn = true;
      } else {
        axios
          .post(
            `${util.api}/getCustomerByPhone`,
            `phone=${this.forgotPassData.formData.email}`,
            {
              headers: {
                "Content-Language": "CN",
                // "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.data.code == "success") {
              this.userError = "";
              this.userInfo = response.data.info;
              this.sendBtn = this.count > 0;
            } else {
              this.userError = "用户不存在";
              this.sendBtn = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    sendEmailCode(event) {
      event.preventDefault();
      axios
          .get(
            `${util.didApi}/register/mailForgetPassword?email=${this.forgotPassData.formData.email.trim()}`,
            {
              headers: {
                "Content-Language": "CN",
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.data.code == 0) {
              this.userError = "";
              this.startCountDown();
            } else {
              this.userError = "User does not exist";
              this.sendBtn = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
    startCountDown() {
      const TIME_COUNT = 60;
      this.count = TIME_COUNT;
      this.show = false;
      this.sendBtn = true;
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
        } else {
          this.show = true;
          clearInterval(this.timer);
          this.timer = null;
          this.sendBtn = false;
        }
      }, 1000);
    },
    submitForgotForm(event) {
      event.preventDefault();
      if (!this.forgotPassData.formData.email.trim()) {
        this.forgotPassData.errors.showErrorEmail = false;
        return;
      }
      if (!this.forgotPassData.formData.verifyCode.trim()) {
        this.forgotPassData.errors.showErrorOtp = false;
        return;
      }
      if (!this.forgotPassData.formData.changePassword.trim()) {
        this.forgotPassData.errors.showErrorPassword = false;
        return;
      }
      if(!this.forgotPassData.errors.showErrorPassword2) {
        return;
      }
      if (!this.confPass.trim()) {
        this.forgotPassData.errors.showErrorConfPassword = false;
        return;
      }
      if (
        this.confPass.trim() !=
        this.forgotPassData.formData.changePassword.trim()
      ) {
        console.log("password is not same");
        this.forgotPassData.errors.passwordMatch = true;
        return;
      } else {
        this.forgotPassData.errors.passwordMatch = false;
      }
      if(this.forgotPassData.formData.email.includes("@")) {
        this.emailSubmit();
      } else {
        this.phoneSubmit();
      }
    },
    phoneSubmit() {
      this.forgotPassData.errors.isForgotSubmit = true;
      const params = `customer_id=${
        this.userInfo
      }&ver_code=${this.forgotPassData.formData.verifyCode.trim()}&password=${this.forgotPassData.formData.changePassword.trim()}&phone=${this.forgotPassData.formData.email.trim()}`;
      axios
        .post(`${util.api}/changePassword`, params, {
          headers: {
            "Content-Language": "CN",
            // "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.code == "success") {
              this.forgotPassData.errors.messageAfterSubmit = "重置成功";
              this.success = true;
              setTimeout(()=> {
                this.$router.push({
                  path: "/zh-login",
                });
              }, 1000)
            } else {
              this.forgotPassData.errors.messageAfterSubmit = response.data.msg || response.data.code;
            }
          this.forgotPassData.errors.isForgotSubmit = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    emailSubmit() {
      this.forgotPassData.errors.isForgotSubmit = true;
      const params = {
        verifyCode: this.forgotPassData.formData.verifyCode.trim(),
        changePassword: this.forgotPassData.formData.changePassword.trim(),
        email: this.forgotPassData.formData.email.trim()
      }
      axios
        .post(`${util.didApi}/register/mailChangePassword`, params, {
          headers: {
            "Content-Language": "CN",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.code == 0) {
              this.forgotPassData.errors.messageAfterSubmit = "重置成功";
              this.success = true;
              setTimeout(()=> {
                this.$router.push({
                  path: "/zh-login",
                });
              }, 1000)
            } else {
              this.forgotPassData.errors.messageAfterSubmit = response.data.msg || response.data.code;
            }
          this.forgotPassData.errors.isForgotSubmit = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
.ap_country_list {
  position: relative;
}

.ap_country_list > span {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background-color: var(--white-color);
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
  display: none;
  overflow-y: scroll;
}

.ap_country_list .countryDD.show {
  display: block;
}

.ap_country_list {
  position: relative;
}

.ap_country_list > span {
  display: block;
  position: relative;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black);
  border-radius: 7px;
  overflow: hidden;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}

.ap_country_list .countryDD {
  background: #fff;
  border-radius: 5px;
  height: 200px;
  position: absolute;
  z-index: 99;
  width: 100%;
}

.ap_country_list .countryDD .c_name {
  background: #fff;
  padding: 3px 15px;
  cursor: pointer;
  font-size: 15px;
}

.ap_country_list .countryDD .c_name:hover {
  background: #e9e9e9;
}

.ap_country_list .countryDD .c_name .flag-icon {
  margin-right: 15px;
}
.ap_country_list::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: transparent;
  z-index: 9;
  transform: rotate(45deg) translateY(-50%);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  right: 20px;
  top: 40%;
  pointer-events: none;
}

.otpButton {
  background-color: #85c100;
  border: 0;
  height: 48px;
  width: 100%;
  border-radius: 7px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.mt-minus {
  margin-top: -20px;
  margin-bottom: 15px;
}
.disabled.otpButton {
  pointer-events: none;
}
</style>
