<template>

    <main>
      <!-- homeBanner -->
      <section class="container-fluid homeBanner textDark">
        <div class="container">
          <div class="row d-flex justify-content-center flex-md-reverse">
            <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
              <div class="row">
                <div class="col-12 col-md-7 d-flex align-items-center">
                  <div class="inner">
                    <h1
                      class="hdng"
                    >
                      Simplify Logistics
                    </h1>
                    <p
                      class="cntnt"
                    >
                      Improve delivery success rates by engaging customers
                      instantly using diverse communication channels.
                    </p>
                    <router-link to="/sign-up" class="cmn_btn light"> 
                      Start now
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#85C100"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </div>
                <div class="col-12 col-md-5 mt-4 mt-md-0">
                  <img
                    src="../../assets/images/industry/logistics_banner_img.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- benifits -->
      <section class="benifits">
        <div class="container">
          <div class="sec_heading text-center">
            <h2>
              Communication Solutions for Seamless <br />Cross-Border Logistics
            </h2>
          </div>
          <div class="content">
            <div class="row justify-content-center">
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/industry/Privacy-Protection.png"
                      alt="benifit_img"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>
                        Enhanced Privacy <br />
                        Protection
                      </h3>
                      <p>
                        A temporary private number is set up for communication
                        between the delivery person and the consignee during the
                        final delivery stage, expiring upon completion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/industry/improve_quality_of_service.svg"
                      alt="benifit_img"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>
                        Improve Quality of <br />
                        Service
                      </h3>
                      <p>
                        Ensure real-time customer communication, promptly
                        sharing shipment alerts, updates, and delivery
                        notifications across logistics stages to elevate
                        customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/industry/increase_delivery_rate.svg"
                      alt="benifit_img"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>
                        Increase <br />
                        Delivery Rate
                      </h3>
                      <p>
                        Reach out, sustain live communication, and secure
                        delivery arrangements via multiple channels including
                        WhatsApp and AICC to connect effectively with
                        users.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- customer_journey_image -->
      <section class="customer_journey_new col_3_2 pt_100">
        <div class="container">
          <div class="sec_heading text-center">
            <h2>Customer Journey</h2>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-md-4">
                <div class="content_box step step_1">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/customer_journey_section/registration_login.svg"
                      alt="registration_login"
                    />
                  </div>
                  <div class="text_wrap">
                    <h3>1. Registration & Login</h3>
                    <div class="tags_wrap">
                      <router-link
                      :to="{ path: '/product-message', query: { id: 'smsOtp' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="smsOtp"
                        data-url="../product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/sms_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">SMS OTP</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{ path: '/product-voice', query: { id: 'voiceOtp' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="voiceOtp"
                        data-url="../product-voice"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/voice_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Voice OTP</span>
                      </a>
                    </router-link>
                      <div class="empty_break"></div>
                      <router-link
                      :to="{ path: '/product-conversation', query: { id: 'whatsappBusiness' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{ path: '/product-fraud-prevention', query: { id: 'OTA' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="OTA"
                        data-url="../product-fraud-prevention"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_12.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">OTA</span>
                      </a>
                    </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="content_box step step_2">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/customer_journey_section/customer_service.svg"
                      alt="icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <h3>2. Customer Service</h3>
                    <div class="tags_wrap">
                      <router-link
                      :to="{ path: '/product-conversation', query: { id: 'whatsappBusiness' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{ path: '/product-customer-engagement', query: { id: 'NXLink' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="NXLink"
                        data-url="../product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_14.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">NXLink</span>
                      </a>
                    </router-link>
                      <div class="empty_break"></div>
                      <router-link
                      :to="{ path: '/product-customer-engagement', query: { id: 'AICC' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <router-link to="/product-global-number" class="tag icon_item"> 
                      <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_09.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Notification SMS</span>
                    </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="content_box step step_3">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/customer_journey_section/order_n_delivery.svg"
                      alt="icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <h3>3. Orders & Delivery</h3>
                    <div class="tags_wrap">
                      <router-link
                      :to="{ path: '/product-conversation', query: { id: 'whatsappBusiness' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link to="/product-global-number" class="tag icon_item"> 
                      <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/global_number.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Global Number</span>
                    </router-link>
                    <router-link
                      :to="{ path: '/product-global-number', query: { id: 'privacyNumber' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="privacyNumber"
                        data-url="../product-global-number"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/private_number.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Privacy Number</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{ path: '/product-customer-engagement', query: { id: 'AICC' } }">
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- customer_journey -->
      <section class="customer_journey_new pt_100 hide_temp">
        <div class="container">
          <div class="sec_heading text-center">
            <h2>Customer Journey</h2>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-12">
                <div class="img_box text-center">
                  <img
                    src="../../assets/images/industry_logistics.svg"
                    alt="industry_logistics.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- customer_journey -->
      <section class="customer_journey pt_100 pb_100 hide_temp">
        <div class="container">
          <div class="sec_heading text-center">
            <h2>Customer Journey</h2>
          </div>
          <div class="content">
            <div class="row justify-content-end">
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/product_icon.svg"
                      alt="product_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>Flow 1</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/product_icon.svg"
                      alt="product_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>Flow 2</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/product_icon.svg"
                      alt="product_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>Flow 3</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/product_icon.svg"
                      alt="product_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>Flow 4</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row flex-row-reverse">
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/product_icon.svg"
                      alt="product_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>Flow 5</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/product_icon.svg"
                      alt="product_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>Flow 6</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="cmn_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/product_icon.svg"
                      alt="product_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <div class="inner">
                      <h3>Flow 7</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- recommended products -->
      <section class="product_sec pt_100 pb_200">
        <div class="container">
          <div class="sec_heading text-center">
            <h2>Recommended product</h2>
          </div>
          <div class="content">
            <div class="row justify-content-center">
              <div class="col-md-6 col-lg-4">
                <div class="product_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/industry/aicc-change.svg"
                      alt="product_box_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <h3>AICC</h3>
                    <p>
                      Merging AI and SaaS technology with traditional call centers, we offer a comprehensive and intelligent solution through an integrated customer service center equipped with marketing and promotion features.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="product_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/industry/privacy-number.svg"
                      alt="product_box_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <h3>Privacy Number</h3>
                    <p>
                      Serving as a temporary contact, the privacy number
                      protects user contact details in communications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-lg-4">
                <div class="product_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/industry/Notification-sms.svg"
                      alt="product_box_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <h3>Notification SMS</h3>
                    <p>
                      Deliver timely notifications sich as holiday wishes,
                      meeting updates, delivery details, class reminders,
                      payment alerts, and more via SMS to enhance customer
                      loyalty.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="product_box">
                  <div class="img_wrap">
                    <img
                      src="../../assets/images/industry/Whatsapp.svg"
                      alt="product_box_icon"
                    />
                  </div>
                  <div class="text_wrap">
                    <h3>WhatsApp Business</h3>
                    <p>
                      Integrated with company applications like marketing
                      platforms and call centers, WhatsApp Business facilitates
                      the exchange of information like photos, videos, links,
                      and PDF files for customer service and marketing usage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cts">
        <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
        <div class="container">
          <div class="row row-cts">
            <div class="col-md-8">
              <h3 class="archived-hdng cts-hdng">
                Start offering your customers a better service experience
              </h3>
            </div>

            <div class="col-md-4">
              <div class="cts-btn">
                <router-link to="/sign-up" class="btn btn-success"> 
                    Get Started
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>    

    <script>
        export default {
            name: 'IndustryLogistics'
        }
    </script>

