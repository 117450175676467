<template>
  <main>
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">
                    让消息传递变得简单
                  </h1>
                  <p class="cntnt">
                    通过优质的短信通道，与全球客户建立紧密的联系。
                  </p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/products_applications/messaging_banner_1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid benifitSection">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                <img
                  src="../../assets/images/products_applications/product_page_img.png"
                  alt=""
                />
              </div>
              <div
                class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0"
              >
                <div class="inner w-100">
                  <h1 class="text-start archived-hdng">我们的优势</h1>

                  <ul class="benifitsList">
                    <li>
                      <strong>覆盖范围广</strong>
                      <span
                        >业务覆盖全球185多个国家和地区，支持多种语言。
                      </span>
                    </li>
                    <li>
                      <strong>全球部署</strong>
                      <span
                        >海外多点部署服务器，支持Web端与API短信接口发送，提交速度毫秒级别。
                      </span>
                    </li>
                    <li>
                      <strong>优质通道</strong>
                      <span
                        >链接全球1000+合作伙伴运营商，专享优质语音通道。
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="container-fluid prod_desc_section pb_200">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <h1 class="archived-hdng">多样化短信产品服务</h1>
          </div>
          <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="tab-1"
                  data-bs-toggle="tab"
                  data-bs-target="#smsOtp"
                  type="button"
                  role="tab"
                  aria-controls="smsOtp"
                  aria-selected="true"
                >
                  <strong> 国际短信验证码 </strong>
                  <span>
                    通过短信向用户发送随机数 <br />字验证码的验证方式。
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-2"
                  data-bs-toggle="tab"
                  data-bs-target="#marketingOtp"
                  type="button"
                  role="tab"
                  aria-controls="marketingOtp"
                  aria-selected="false"
                >
                  <strong> 国际营销短信 </strong>
                  <span>
                    通过短信进行企业产品推广、<br />品牌宣传的一种方式。
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-3"
                  data-bs-toggle="tab"
                  data-bs-target="#notificationOtp"
                  type="button"
                  role="tab"
                  aria-controls="notificationOtp"
                  aria-selected="false"
                >
                  <strong> 国际通知短信 </strong>
                  <span>
                    用于企业给客户提供精准 <br />
                    的短信通知服务。
                  </span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="tab-4"
                  data-bs-toggle="tab"
                  data-bs-target="#emailOtp"
                  type="button"
                  role="tab"
                  aria-controls="emailOtp"
                  aria-selected="false"
                >
                  <strong> 邮件验证码 </strong>
                  <span> 通过邮件获取验证码以及身份确认的一种验证方式。 </span>
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="smsOtp"
                role="tabpanel"
                aria-labelledby="tab-1"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/effortless_security.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">注册登录</h4>
                    <p class="cntnt">
                      用于APP、网站等平台快捷注册与登录，一键即达、方便快捷。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/forget_me_not.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">密码找回</h4>
                    <p class="cntnt">
                      用于平台用户快速找回密码，并完成密码修改，确保帐户安全。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Payment_icon.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">支付验证</h4>
                    <p class="cntnt">
                      用于线上支付交易时，核验用户身份，有效保障账户资金安全，避免财产损失。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="marketingOtp"
                role="tabpanel"
                aria-labelledby="tab-2"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Effecient_User_Acquisition.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">用户拉新</h4>
                    <p class="cntnt">
                      通过短信将新用户注册福利、充值优惠、注册链接等营销信息发送给消费者，以较低的成本快速实现用户拉新与引流。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Omnichannel_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">产品推广</h4>
                    <p class="cntnt">
                      用于跨境电商、手游、在线社交等平台以及其他出海企业的产品推广，可精准触达目标客户，成本更低、营销更灵活。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Targeted_marketing.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">用户召回</h4>
                    <p class="cntnt">
                      通过短信将新品折扣、限时促销、会员福利等营销信息传递给老用户，以较低成本快速实现沉睡用户召回。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="notificationOtp"
                role="tabpanel"
                aria-labelledby="tab-3"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Create_lifelong_customers.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">会员服务</h4>
                    <p class="cntnt">
                      为会员客户提供贴心周到的服务通知，提升平台好感度，从而增强客户忠诚度。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Attentive_customer_care.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">事项通知</h4>
                    <p class="cntnt">
                      及时给用户发送上课通知、还款通知、<br />充值通知以及收货通知等各类通知、<br />提醒信息。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/hand_shake.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">节日祝福</h4>
                    <p class="cntnt">
                      通过短信向客户发送真诚的问候和祝福，丰富客户VIP体验，以提升客户满意度，<br />增强客户粘性。
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="emailOtp"
                role="tabpanel"
                aria-labelledby="tab-4"
                tabindex="0"
              >
                <div class="row d-flex justify-content-center">
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/effortless_security.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">注册登录</h4>
                    <p class="cntnt">
                      用于APP、网站等平台的用户注册与快速登录，对用户身份进行验证，以保障平台用户信息安全。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/forget_me_not.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">密码找回</h4>
                    <p class="cntnt">
                      用于平台用户找回密码时，向用户邮箱发送验证码进行验证，以快速、安全地找回密码，并完成密码修改。
                    </p>
                  </div>
                  <div class="col-12 col-sm-10 col-md-4">
                    <figure>
                      <img
                        src="../../assets/images/products_applications/Payment_icon.svg"
                        alt="function-image"
                      />
                    </figure>
                    <h4 class="hdng">支付认证</h4>
                    <p class="cntnt">
                      用于线上支付交易时，通过邮件发送验证码给用户，核验用户身份，有效保障账户资金安全，避免财产损失。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="archived-card-section hide_temp">
      <div class="container">
        <div class="row row-archived">
          <div class="col-md-12">
            <h3 class="archived-hdng">Testimonial</h3>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/dbs.svg"
                    class="img-fluid"
                    alt="card"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/slack.svg"
                    class="img-fluid"
                    alt="slack"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="archived-card-wrap">
                  <img
                    src="../../assets/images/hsbc.svg"
                    class="img-fluid"
                    alt="hsbc"
                  />
                  <h4 class="archived-card-hdng">
                    Increased agent productivity by 2.7x
                  </h4>
                  <p class="archived-card-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                    pharetra congue. Interdum et malesuada
                  </p>

                  <div class="archived-card-numbering-wrap">
                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">3.5B+</h5>
                      <p class="card-number-desc">
                        Increase in <br />
                        marketing opt-in
                      </p>
                    </div>

                    <div class="archived-card-number">
                      <h5 class="card-number-hdng">500K</h5>
                      <p class="card-number-desc">
                        WhatsApp messages <br />
                        per month
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供 <br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "ZhProductMessage",
};
</script>