<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">Making Financial Services Safer</h1>
                  <p class="cntnt">
                    Harness the power of our fintech solutions for enhanced
                    marketing precision and strategic risk mitigation. Increase
                    sales conversions and optimize debt repayment rates.
                  </p>
                  <router-link to="/sign-up" class="cmn_btn light">
                    Start now
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/industry/fintech_banner.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benifits -->
    <section class="benifits">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>
            Safer Cross-Border Banking and <br />
            Overseas Transactions
          </h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/lower_cost_arrow.svg"
                    alt="lower_cost_arrow."
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Lower Acquisition Cost</h3>
                    <p>
                      Leveraging our SMS, Voice, WhatsApp, and Viber solutions
                      to create an automated fintech-driven marketing system for
                      targeted customer acquisition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/account_protection.svg"
                    alt="account_protection"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Reinforce Information Security</h3>
                    <p>
                      Capture platform user contact information during
                      verification processes such as registration, quick login,
                      or remote login to aid in remarketing and debt collection.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/reduce_bBad_debt.svg"
                    alt="reduce_bBad_debt"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Reduce Bad Debt</h3>
                    <p>
                      Build a robust risk management framework by combining AI
                      audit, AI overdue notifications, and AI reminders,
                      enhancing the efficiency and repayment rates in debt
                      collection.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new col_3_3 pt_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-4">
              <div class="content_box step step_1">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/growth_marketing.svg"
                    alt="growth_marketing"
                  />
                </div>
                <div class="text_wrap">
                  <h3>1. Growth Marketing</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/product-message',
                        query: { id: 'marketingOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="marketingOtp"
                        data-url="../product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_02.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Marketing SMS</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/product-fraud-prevention',
                        query: { id: 'numberCheck' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="numberCheck"
                        data-url="../product-fraud-prevention"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_11.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Number Check</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/product-customer-engagement',
                        query: { id: 'AICC' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_2">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/customer_service.svg"
                    alt="customer_service"
                  />
                </div>
                <div class="text_wrap">
                  <h3>2. Customer Service</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/product-conversation',
                        query: { id: 'whatsappBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/product-conversation',
                        query: { id: 'viberBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="viberBusiness"
                        data-url="../product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/viber_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Viber Business</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/product-conversation',
                        query: { id: 'zaloBusiness' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="zaloBusiness"
                        data-url="../product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/zalo_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Zalo Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/product-customer-engagement',
                        query: { id: 'AICC' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <router-link
                      to="/product-global-number"
                      class="tag icon_item"
                    >
                      <i class="icon">
                        <img
                          src="../../assets/images/customer_journey_section/global_number.svg"
                          alt="icon"
                        />
                      </i>
                      <span class="text">Global Number</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_3">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/customer_alert.svg"
                    alt="customer_alert"
                  />
                </div>
                <div class="text_wrap">
                  <h3>3. Customer Alerts</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/product-message',
                        query: { id: 'smsOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="smsOtp"
                        data-url="../product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_09.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">SMS OTP</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/product-voice',
                        query: { id: 'voiceOtp' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="voiceOtp"
                        data-url="../product-voice"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_03.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">Voice OTP</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/product-customer-engagement',
                        query: { id: 'AICC' },
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey_new pt_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-12">
              <div class="img_box text-center">
                <img
                  src="../../assets/images/industry_fintech.svg"
                  alt="industry_fintech.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row justify-content-end">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 1</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 2</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 3</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row flex-row-reverse">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 6</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 7</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recommended products -->
    <section class="product_sec pt_100 pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Recommended Products</h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/privacy_icon.svg"
                    alt="privacy_number"
                  />
                </div>
                <div class="text_wrap">
                  <h3>Privacy Number</h3>
                  <p>
                    Designed as a temporary contact, the privacy number protects
                    user contact details during communications.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/aicc-change.svg"
                    alt="NXcallbot"
                  />
                </div>
                <div class="text_wrap">
                  <h3>AICC</h3>
                  <p>
                    Merging AI and SaaS technology with traditional call
                    centers, we offer a comprehensive and intelligent solution
                    through an integrated customer service center equipped with
                    marketing and promotion features.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/email_otp_icon.svg"
                    alt="email_OTP"
                  />
                </div>
                <div class="text_wrap">
                  <h3>Email OTP</h3>
                  <p>
                    Customer security is ensured through a single verification
                    email used for identity confirmation during registrations,
                    logins, or password retrievals.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/voice_notification_icon.svg"
                    alt="voice_notifications"
                  />
                </div>
                <div class="text_wrap">
                  <h3>Voice Notification</h3>
                  <p>
                    Empower enterprises with our solution to send telemarketing
                    data via batch outbound voice calls to maximized the impact
                    of your brand and product advertising efforts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              Start offering your customers a better service experience
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/sign-up" class="btn btn-success">
                Get Started
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "HomePage",
};
</script>
