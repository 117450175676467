import { createApp } from 'vue'
import App from './App.vue'

// Owl bootstrap
import 'bootstrap/dist/css/bootstrap.css'; // Import Bootstrap styles
import 'bootstrap'; // Import Bootstrap JavaScript components

// assets files
import './assets/fonts/stylesheet.css'; // Import custom style file
import './assets/css/animate.css';
import './assets/css/github-style.css';
import './assets/css/header-style.css';
import './assets/css/mohit_style.css';
import './assets/css/404.css';
import './assets/css/style.css';
import './assets/css/common.less';

import store from './utils/store'  

// router
import router from './routes.js'

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
import i18n from './locale/index.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

// for dynamic title 
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'NXCloud';
    if (to.meta.description) {
      let head = document.getElementsByTagName("head");
      let meta = document.createElement("meta");
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", to.meta.description);
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", to.meta.key);
      meta.content = to.meta.description;
      head[0].appendChild(meta);
    }
    // console.log("wwwwwwwwww = ", to)
    if(to.query.gad_source !== undefined || to.query.gclid !== undefined) {
      store.commit('updateWhereFrom', "Google");
    }else if(to.query !== undefined && to.query !== null) {
      let keys = Object.keys(to.query);  
      keys.forEach(key => {
        if(key.startsWith("baidu")) {
          store.commit('updateWhereFrom', "baidu");
        }
      });
    }
    if(to.query.id) {
      next();
      return;
    }else if(to.fullPath === "/document" || to.fullPath === "/document/") {
      next({
        path: "/document/sms",
      })
      return;
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });
    next();
});

createApp(App).use(VMdPreview).use(router).use(store).use(i18n).mount('#app')
