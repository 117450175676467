<template>

    <main>
      <section class="container-fluid homeBanner textDark">
        <div class="container">
          <div class="row d-flex justify-content-center flex-md-reverse">
            <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
              <div class="row">
                <div class="col-12 col-md-7 d-flex align-items-center">
                  <div class="inner">
                    <h1
                      class="hdng"
                    >
                      Take your business to the next level
                    </h1>
                    <p
                      class="cntnt"
                    >
                      Transform customer engagement by integrating your business
                      with cutting-edge technology.
                    </p>
                    <router-link to="/sign-up" class="cmn_btn light"> 
                      Start now
                      <svg
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                          stroke="#85C100"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </router-link>
                  </div>
                </div>
                <div class="col-12 col-md-5 mt-4 mt-md-0">
                  <img
                    src="../../assets/images/products_applications/product_customer_engagement_banner.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container-fluid benifitSection">
        <div class="container">
          <div class="row d-flex justify-content-center flex-md-reverse">
            <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
              <div class="row">
                <div
                  class="col-12 col-md-12 col-lg-6 d-flex align-items-center"
                >
                  <img
                    src="../../assets/images/products_applications/product_page_img.png"
                    alt="Product_group"
                  />
                </div>
                <div
                  class="col-12 col-md-12 col-lg-6 d-flex align-items-center mt-4 mt-lg-0" 
                >
                  <div class="inner w-100">
                    <h1
                      class="text-start archived-hdng"
                    >
                      Engage with confidence
                    </h1>

                    <ul class="benifitsList">
                      <li
                      >
                        <strong>Omnichannel Connections</strong>
                        <span
                          >Our products integrate SMS, WhatsApp, and other
                          communication capabilities for precise, multi-channel
                          customer outreach.</span
                        >
                      </li>
                      <li
                      >
                        <strong>Integrated Management</strong>
                        <span
                          >Assistance provided for customizing multi-lingual
                          intelligent agents and call center
                          configurations.</span
                        >
                      </li>
                      <li
                      >
                        <strong>Built to Adapt</strong>
                        <span
                          >Readily usable for customer service, message
                          notifications, call-backs and other purposes.</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container-fluid prod_desc_section pb_200">
        <div class="container">
          <div class="row d-flex justify-content-center flex-md-reverse">
            <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
              <h1
                class="archived-hdng"
              >
                Connecting it all together
              </h1>
            </div>
            <div class="col-12">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="tab-1"
                    data-bs-toggle="tab"
                    data-bs-target="#NXLink"
                    type="button"
                    role="tab"
                    aria-controls="NXLink"
                    aria-selected="true"
                  >
                    <div class="icon_text">
                      <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/NXLink.svg"
                        alt="icon"
                      />
                    </i> -->
                      <strong> NXLink </strong>
                    </div>
                    <span>
                      Combine SMS, Voice, WhatsApp and Viber for precise sales
                      conversions
                    </span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="tab-2"
                    data-bs-toggle="tab"
                    data-bs-target="#AICC"
                    type="button"
                    role="tab"
                    aria-controls="AICC"
                    aria-selected="false"
                  >
                    <div class="icon_text">
                      <!-- <i class="icon">
                      <img
                        src="../../assets/images/products_applications/AICC.svg"
                        alt="icon"
                      />
                    </i> -->
                      <strong> NXLink </strong>
                    </div>
                    <span>
                      Revolutionize your business with our AI-driven customer
                      support hub</span
                    >
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="NXLink"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                  tabindex="0"
                >
                  <div class="row d-flex justify-content-center">
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Attentive_customer_care.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">
                        Seamless <br />
                        Customer Service
                      </h4>
                      <p class="cntnt">
                        Enables seamless communication via WhatsApp and Viber,
                        responding promptly to customer queries while fostering
                        effective customer service interactions.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/After_sales_care.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">
                        After Sales <br />
                        Care
                      </h4>
                      <p class="cntnt">
                        Deliver comprehensive after-sales notifications—product
                        details, transaction specifics, logistics—via global
                        SMS, WhatsApp, and Viber, enhancing post-purchase
                        service for a distinct competitive edge.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Technology_driven_retargeting.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">
                        Technology Driven <br />
                        Retargeting
                      </h4>
                      <p class="cntnt">
                        Reignite customer interest through the distribution of
                        marketing materials—coupons, returning customer
                        incentives, and new product highlights—through
                        international SMS, WhatsApp, and Viber to encourage
                        renewed purchases and drive conversions.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="AICC"
                  role="tabpanel"
                  aria-labelledby="tab-2"
                  tabindex="0"
                >
                  <div class="row d-flex justify-content-center">
                    <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Faster_better_service.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">
                        Faster Better <br />
                        Service
                      </h4>
                      <p class="cntnt">
                        Expedite the resolution of customer inquiries and
                        complaints to improve customer service and elevate your
                        brand image.
                      </p>
                    </div>
                    <div class="col-12 col-sm-10 col-md-4 flex-grow-1">
                      <figure>
                        <img
                          src="../../assets/images/products_applications/Smarter_marketing.svg"
                          alt="function-image"
                        />
                      </figure>
                      <h4 class="hdng">
                        Smarter <br />
                        Marketing
                      </h4>
                      <p class="cntnt">
                        Combined with traditional phone marketing to effectively
                        promote company offerings and drive successful sales
                        conversions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="archived-card-section hide_temp">
        <div class="container">
          <div class="row row-archived">
            <div class="col-md-12">
              <h3
                class="archived-hdng"
              >
                Testimonial
              </h3>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
              <div
                class="card"
              >
                <div class="card-body">
                  <div class="archived-card-wrap">
                    <img
                      src="../../assets/images/dbs.svg"
                      class="img-fluid"
                      alt="card"
                    />
                    <h4 class="archived-card-hdng">
                      Increased agent productivity by 2.7x
                    </h4>
                    <p class="archived-card-desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                      pharetra congue. Interdum et malesuada
                    </p>

                    <div class="archived-card-numbering-wrap">
                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">3.5B+</h5>
                        <p class="card-number-desc">
                          Increase in <br />
                          marketing opt-in
                        </p>
                      </div>

                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">500K</h5>
                        <p class="card-number-desc">
                          WhatsApp messages <br />
                          per month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
              <div
                class="card"
              >
                <div class="card-body">
                  <div class="archived-card-wrap">
                    <img
                      src="../../assets/images/slack.svg"
                      class="img-fluid"
                      alt="slack"
                    />
                    <h4 class="archived-card-hdng">
                      Increased agent productivity by 2.7x
                    </h4>
                    <p class="archived-card-desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                      pharetra congue. Interdum et malesuada
                    </p>

                    <div class="archived-card-numbering-wrap">
                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">3.5B+</h5>
                        <p class="card-number-desc">
                          Increase in <br />
                          marketing opt-in
                        </p>
                      </div>

                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">500K</h5>
                        <p class="card-number-desc">
                          WhatsApp messages <br />
                          per month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
              <div
                class="card"
              >
                <div class="card-body">
                  <div class="archived-card-wrap">
                    <img
                      src="../../assets/images/hsbc.svg"
                      class="img-fluid"
                      alt="hsbc"
                    />
                    <h4 class="archived-card-hdng">
                      Increased agent productivity by 2.7x
                    </h4>
                    <p class="archived-card-desc">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Curabitur nec mi enim. Vestibulum tincidunt risus in orci
                      pharetra congue. Interdum et malesuada
                    </p>

                    <div class="archived-card-numbering-wrap">
                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">3.5B+</h5>
                        <p class="card-number-desc">
                          Increase in <br />
                          marketing opt-in
                        </p>
                      </div>

                      <div class="archived-card-number">
                        <h5 class="card-number-hdng">500K</h5>
                        <p class="card-number-desc">
                          WhatsApp messages <br />
                          per month
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cts">
        <img src="../../assets/images/footer-shape.png" alt="" class="ftr_shape" />
        <div class="container">
          <div class="row row-cts">
            <div class="col-md-8">
              <h3 class="archived-hdng cts-hdng">
                Start offering your customers a better service experience
              </h3>
            </div>

            <div class="col-md-4">
              <div class="cts-btn">
                <router-link to="/sign-up" class="btn btn-success"> 
                    Get Started
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>    

<script>
    export default {
        name: 'ProductCustomerEngagement'
    }
</script>

    
