<template>
  <main>
    <!-- homeBanner -->
    <section class="container-fluid homeBanner textDark">
      <div class="container">
        <div class="row d-flex justify-content-center flex-md-reverse">
          <div class="col-12 col-sm-10 col-md-11 col-lg-10 col-xl-11">
            <div class="row">
              <div class="col-12 col-md-7 d-flex align-items-center">
                <div class="inner">
                  <h1 class="hdng">跨境物流解决方案</h1>
                  <p class="cntnt">
                    为跨境物流公司提供登录认证、物流提醒、派送通知等全场景通信服务
                  </p>
                  <router-link to="/zh-sign-up" class="cmn_btn light">
                    立即体验
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5 4.25L15.75 9.5M15.75 9.5L10.5 14.75M15.75 9.5L2.25 9.5"
                        stroke="#85C100"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-md-5 mt-4 mt-md-0">
                <img
                  src="../../assets/images/industry/logistics_banner_img.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- benifits -->
    <section class="benifits">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>为跨境物流企业定制完整的 <br />行业解决方案</h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Privacy-Protection.png"
                    alt="benifit_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>加强隐私保护</h3>
                    <p>
                      在终端物流配送环节，系统可自动为用户配置隐私号码，作为配送员与收货人联系的中间号码，有效保护双方隐私安全，增强客户信任度。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/improve_quality_of_service.svg"
                    alt="benifit_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>提升服务质量</h3>
                    <p>
                      通过多样化产品，在商品发货、物流更新、订单派送等各服务环节，实时与用户保持沟通，及时将订单最新信息告知客户，提升客户体验感和满意度。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/increase_delivery_rate.svg"
                    alt="benifit_img"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>提升订单签收率</h3>
                    <p>
                      通过多样化通道，快速与用户建立沟通和联系，提前预约派送时间和地点，避免耽误用户及时取件，降低COD拒签率。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey_image -->
    <section class="customer_journey_new col_3_2 pt_100">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>客户全旅程服务</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-md-4">
              <div class="content_box step step_1">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/registration_login.svg"
                    alt="registration_login"
                  />
                </div>
                <div class="text_wrap">
                  <h3>1. 注册登录</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-message',
                        query: { id: 'smsOtp' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="smsOtp"
                        data-url="../zh-product-message"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/sms_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">短信验证码</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-voice',
                        query: { id: 'voiceOtp' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="voiceOtp"
                        data-url="../zh-product-voice"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/voice_otp.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">语音验证码</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-fraud-prevention',
                        query: { id: 'OTA' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="OTA"
                        data-url="../zh-product-fraud-prevention"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_12.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">一键登录</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_2">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/customer_service.svg"
                    alt="icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>2. 客户服务</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-customer-engagement',
                        query: { id: 'NXLink' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="NXLink"
                        data-url="../zh-product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/hdr_icon_14.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">NXLink</span>
                      </a>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-customer-engagement',
                        query: { id: 'AICC' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                    <router-link
                      to="/zh-product-global-number"
                      class="tag icon_item"
                    >
                      <i class="icon">
                        <img
                          src="../../assets/images/hdr_icon_09.svg"
                          alt="icon"
                        />
                      </i>
                      <span class="text">通知短信</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content_box step step_3">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/customer_journey_section/order_n_delivery.svg"
                    alt="icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>3. 订单派送</h3>
                  <div class="tags_wrap">
                    <router-link
                      :to="{
                        path: '/zh-product-conversation',
                        query: { id: 'whatsappBusiness' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="whatsappBusiness"
                        data-url="../zh-product-conversation"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/whatsapp_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">WhatsApp Business</span>
                      </a>
                    </router-link>
                    <router-link
                      to="/zh-product-global-number"
                      class="tag icon_item"
                    >
                      <i class="icon">
                        <img
                          src="../../assets/images/customer_journey_section/global_number.svg"
                          alt="icon"
                        />
                      </i>
                      <span class="text">全球虚拟号码</span>
                    </router-link>
                    <div class="empty_break"></div>
                    <router-link
                      :to="{
                        path: '/zh-product-global-number',
                        query: { id: 'privacyNumber' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="privacyNumber"
                        data-url="../zh-product-global-number"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/private_number.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">隐私号码</span>
                      </a>
                    </router-link>
                    <router-link
                      :to="{
                        path: '/zh-product-customer-engagement',
                        query: { id: 'AICC' }
                      }"
                    >
                      <a
                        href="javascript:void(0);"
                        class="tabClickLink tag icon_item"
                        data-tabclick="AICC"
                        data-url="../zh-product-customer-engagement"
                      >
                        <i class="icon">
                          <img
                            src="../../assets/images/customer_journey_section/aicc_icon.svg"
                            alt="icon"
                          />
                        </i>
                        <span class="text">AICC</span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey_new pt_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-12">
              <div class="img_box text-center">
                <img
                  src="../../assets/images/industry_logistics.svg"
                  alt="industry_logistics.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- customer_journey -->
    <section class="customer_journey pt_100 pb_100 hide_temp">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>Customer Journey</h2>
        </div>
        <div class="content">
          <div class="row justify-content-end">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 1</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 2</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 3</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 4</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row flex-row-reverse">
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 5</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 6</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="cmn_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/product_icon.svg"
                    alt="product_icon"
                  />
                </div>
                <div class="text_wrap">
                  <div class="inner">
                    <h3>Flow 7</h3>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- recommended products -->
    <section class="product_sec pt_100 pb_200">
      <div class="container">
        <div class="sec_heading text-center">
          <h2>产品推荐</h2>
        </div>
        <div class="content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/aicc-change.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>AICC</h3>
                  <p>
                    融合传统呼叫中心和AI智能技术，为企业提供全方位、智能化客户服务方案，轻松、快捷搭建集客户服务、营销推广等场景的一体化客户服务中心。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/privacy-number.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>隐私号码</h3>
                  <p>
                    在通信过程中代替真实号码进行显号，以实现在不透露用户真实号码信息的前提下进行常规通信业务，从而保护双方个人信息安全。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Notification-sms.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>通知短信</h3>
                  <p>
                    短信给用户发送提醒和通知类信息，如节日祝福、会议通知、收货通知、上课提醒、支付提醒等。实现精准的通知服务，给予客户关怀，提升企业好感度和客户满意度。
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="product_box">
                <div class="img_wrap">
                  <img
                    src="../../assets/images/industry/Whatsapp.svg"
                    alt="product_box_icon"
                  />
                </div>
                <div class="text_wrap">
                  <h3>WhatsApp Business</h3>
                  <p>
                    支持快速接入企业营销管理平台、呼叫中心、客服系统等应用，帮助企业进行客户服务和产品二次营销，高效触达目标用户。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng">
              开始为您的客户提供<br />
              更优质的服务体验
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                开始使用
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "ZhIndustryLogistics"
};
</script>
