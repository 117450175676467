<template>
  <div class="empty-root">
    <img src="../../assets/images/icEmpty.svg" alt="empty" class="empty-image">
    <span class="empty-title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      
    }
  },
  methods: {
    
  },
  mounted() {
    
  },
}
</script>

<style lang="less" scoped>
.empty-root {
  height: 560px; 
  width: 100%; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  flex-direction: column;
}

.empty-image {
  width: 180px;
  height: 180px;
}

.empty-title {
  font-size: 30px; 
  color: #333333; 
  margin-top: 40px;
}

@media(min-width: 544px) {
  .empty-image {
    width: 240px;
    height: 240px;
  }
}

@media(min-width: 768px) {
  .empty-image {
    width: 300px;
    height: 300px;
  }
}

@media(min-width: 1012px) {
  .empty-image {
    width: 360px;
    height: 360px;
  }
}

</style>