<template>
  <main>
    <section class="common-section">
      <div style="background-color: white; padding: 120px 0;">
        <div class="container common-column-center">
          <span class="title-black-54-600">{{ $t("serviceStatus") }}</span>
          <span style="margin-top: 10px;">{{ $t("serviceStatusTip") }}</span>
          <div class="common-column-center" style="margin-top: 60px; align-self: stretch;">
            <div v-for="(item, index) in list" :key="index" class="item-root" :class="{ 'open': item.isOpened }">
              <div class="common-row-center" style="cursor: pointer;" @click="item.isOpened =!item.isOpened">
                <span class="item-title">
                  {{ item.serviceName }}
                </span>
                <img
                  :src="item.serviceStatus === 1 ? require(`@/assets/images/icServiceN.svg`) : require(`@/assets/images/icServiceE.svg`)"
                  alt=""
                  class="service-status-icon"
                />
                <span class="item-status-text">
                  {{ getServiceStatusInfo(item) }}
                </span>
                <img
                  :src="require(`@/assets/images/icArrowRight.svg`)"
                  alt=""
                  class="arrow-icon"
                  :class="{ 'rotate': item.isOpened }"
                />
              </div>
              <template v-if="item.isOpened">
                <div class="line"></div>
                <div class="common-row-center">
                  <img
                    :src="item.serviceStatus === 1 ? require(`@/assets/images/icServiceN.svg`) : require(`@/assets/images/icServiceE.svg`)"
                    alt=""
                    class="service-status-icon"
                  />
                  <span class="item-time">
                    {{ dayjs(item.gmtModified).format("YYYY-MM-DD HH:mm:ss") }}
                    <span v-if="item.serviceStatus === 1">
                      {{ $t("serviceStatus5") }}
                    </span>
                  </span>
                  <div class="common-btn" @click="toRecords(item)">
                    {{ $t('checkRecords') }}
                  </div>
                </div>
                <span class="item-reason" v-if="item.serviceStatus !== 1">
                  {{ getStatusDescription(item) }}
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section style="background: rgba(157, 201, 60, 1); height: 105px;"/> -->
    <section class="cts">
      <img
        src="../../assets/images/footer-shape.png"
        alt=""
        class="ftr_shape"
      />
      <div class="container">
        <div class="row row-cts">
          <div class="col-md-8">
            <h3 class="archived-hdng cts-hdng" style="white-space: pre-wrap">
              {{ $t('footerTip1') }}
            </h3>
          </div>

          <div class="col-md-4">
            <div class="cts-btn">
              <router-link to="/zh-sign-up" class="btn btn-success">
                {{ $t('footerTip2') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { productServiceGet } from "@/api/getData.js";
import dayjs from "dayjs";

export default {
  name: "ServiceStatus",
  data() {
    return {
      dayjs,
      list: [],
    };
  },
  methods: {
    getServiceStatusInfo(item) {
      return localStorage.lang === "zh-CN" ? item.serviceStatusInfoCn : item.serviceStatusInfoEn;
    },
    getStatusDescription(item) {
      return localStorage.lang === "zh-CN" ? item.statusDescriptionCn : item.statusDescriptionEn;
    },
    toRecords(item) {
      this.$router.push({
        name: localStorage.lang === "zh-CN" ? "ZhServiceStatusRecord" : "ServiceStatusRecord",
        query: {
          serviceId: item.id,
          serviceName: item.serviceName,
        },
      });
    },
    getList() {
      productServiceGet().then((res) => {
        if(res.data && res.data.code === 0){
          this.list = res.data.data;
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
}
</script>

<style lang="less" scoped>
.item-root {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #DDDDDD;
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  // transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  // max-height: 102px; /* 初始高度 */
  margin-top: 20px;
}

.item-root.open {
    // max-height: 2000px; /* 大于内容的最大高度 */
}

.item-title {
  flex: 1;
  font-weight: 600;
  font-size: 26px;
  color: #000000;
  line-height: 40px;
  word-break: break-word;
}

.service-status-icon {
  width: 36px;
  height: 36px;
}

.item-status-text {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  line-height: 24px;
  margin-left: 12px;
}

.arrow-icon {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  transition: transform 0.3s ease-out;
}

.arrow-icon.rotate {
    transform: rotate(90deg); /* 向下箭头 */
}

.line {
  height: 1px;
  background: #DDDDDD;
  margin: 30px 0;
}

.item-time {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  margin-left: 14px;
  flex: 1;
}

.item-reason {
  margin-left: 49px;
  font-weight: 400;
  font-size: 16px;
  color: #454545;
  line-height: 20px;
  margin-top: 22px;
  word-break: break-word;
}
</style>